import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  group: {
      height: 500,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  }
}));

function Group({group}) {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth={false} component="section" sx={{display: 'flex', pt: 0 }}>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column' sx={{mt: 7, mb: 8 }}>
            <Grid container spacing={2.5}>
              {group.map((card) => (
                <Grid item key={card.title} xs={12} md={6}>
                    <div className={classes.group} style={{backgroundImage: `url(${card.img}`,}}></div>
                </Grid>
              ))}
            </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Group;