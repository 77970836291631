import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ProductCTA from '../Home/ProductCTA';
import CategoryHead from './CategoryHead';
import HorizontalLinearStepper from './Stepper';
import WhatWeDo from '../Home/WhatWeDo';
import Group from '../Common/Group';
import Testimonial from './Testimonial';
import HowStarted from '../Home/HowStarted';
const howStarted = {
  tagline: 'CAYMAN ISLAND BUSINESSES AND BEYOND',
  title: 'Web Design And Development Services',
  description:
    "We offer up-to-the-minute web design and development solutions to various Cayman Island businesses and beyond. We know how important your business is, and it is for this reason that our web design and development experts are here to help you find fitting solutions. Our solutions are designed to be aesthetically appealing and are guaranteed to take your business to the next level.",
  descriptionTwo:
    "Our aim is to come up with innovative, functional, and clean solutions that adhere to the SEO requirements of various search engines.",
  descriptionThree:
    "We have helped many businesses design table-turning websites. We go to great lengths to deliver high-quality web design solutions. Looking to overhaul your current webpage or achieve other web design and development milestones? Give us a call today!",
  image: `${process.env.REACT_APP_IMG}/v1640246255/CayMedia/about.jpg`,
  buttonText: 'Learn more about Us',
  btn: false,
};
const post = [
{
  mainFeaturedPost : {
    tagline: 'Marketing Strategy',
    preTitle: 'Realising your long-term marketing strategy is paramount to your company’s success.',
    description:
      "That’s where we come in… Focusing on your marketing research results, our team will assemble an overall plan to bring your vision to fruition. Caymedia helps brands achieve their business goals, increase their sales and establish a positive influence on society. We want to take your marketing objectives to new heights and push competition right over the edge.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Our Process',
    title: 'Custom-tailored Marketing Strategy',
    description: 'As a digital marketing creator and SEO leader , Caymedia is committed to taking your Marketing Strategy to the next level.',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
      },
    ]
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Market Research',
    desc: 'Know your competition, define your target market, implement controls. These are the essential starting points for building a successful Marketing Strategy.',
  }, {
    lable: '',
    title: 'Implementation',
    desc: 'Your business needs a goal, a direction. Following on from your extensive marketing research, we will take these results and develop an overall Marketing Strategy for success.',
  },{
    lable: '',
    title: 'Technology',
    desc: 'The world is now a digital playground. Adapting to new technologies will guarantee your company’s Marketing Strategy secures a place in today’s modern business environment.',
  },{
    lable: '',
    title: 'Results',
    desc: 'Increasing sales, edging out the competition, optimizing your resources and knowledge in the industry, all these elements will be the result of a successful Marketing Strategy.',
  }],
}, 
{
  mainFeaturedPost : {
    tagline: 'Brand Design & Development',
    preTitle: 'Standing out from the crowd is imperative when it comes to Branding.',
    description:
      "Caymedia will walk you through the process from inception to direction to creation. Whatever your industry, developing a powerful, consistent and unique identity will separate you from your competitors. Through effective brand management, Caymedia will increase your customer loyalty and fuel company profits.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Our Process',
    title: 'Custom-tailored Brand Design & Development',
    description: 'Creating, strengthening or redesigning your brand determines your company’s place in the market.',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
      },
    ]
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Branding',
    desc: 'Your brand is your most valuable asset. The Caymedia team first need to analyze how your customers view your products and services.',
  }, {
    lable: '',
    title: 'Planning',
    desc: 'Next, through extensive planning and positioning, and whilst making sure to maintain the business’ unique identity, we develop a brand image that works for you.',
  },{
    lable: '',
    title: 'Establishment',
    desc: 'Caymedia holds the effective tools to create a brand identity that will elevate you from your competitors. Focusing on logos, taglines, fonts and colors will help in establishing your brand.',
  },{
    lable: '',
    title: 'Evaluation',
    desc: 'Launching and tracking the branding strategy is the final indicator in evaluating your results and ensuring that the brand meets your company’s objectives. Caymedia will be ever-present in celebrating the success of the brand and, ultimately, the goals of your company.',
  }],
},
{
  mainFeaturedPost : {
    tagline: 'Website Design & Development',
    preTitle: 'We Provide best Digital Marketing and SEO Services',
    description:
      "Websites are very important today because they provide businesses with the means to reach a wide audience quickly and virtually. Commerce and global trade are receiving a lot of interest, and it is for this reason that businesses are turning to web design and development to achieve an integrated marketing strategy.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Cayman Island businesses and beyond',
    title: 'Up-to-the-minute web design and development',
    description: '',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
        desc: 'The world is going digital, and it is important for businesses to adopt emerging digital marketing tools and techniques. Digital marketing is one way your businesses can interact with customers and new prospects. CayMedia is one of the top marketing and development business in the Cayman Islands and beyond. Almost three years of providing digital marketing services to a local and international clientele is the reason we are the most sought-after business.',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
        desc: 'A great portion of the online experiences potential customers start with are search engines. Through our meticulous research in keyword usage, we have been able to help businesses rank high and attract the right kind of traffic. Our services in this area include on and off-page SEO. We help you streamline your presence online, and track the results that matter to increase conversions.',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
        desc: 'CayMedia also supports and empowers smart insights and models about the future prospects of your business so that you can make knowledgeable decisions. We use data and new tools created to help your business address challenges. We employ specialized data management, predictive and illustrative modelling as well as geospatial methods to help your business overcomes current and new challenges.',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
        desc: 'Customers expect a holistic experience across all fronts and are easily as likely to shift their preferences after a single interaction. As such, your business needs to fill gaps and engage with customers one on one in order to offer an integrated, personalised, and satisfactory experience.',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
        desc: 'Interacting with customers boils down to establishing trust and addressing their questions and concerns. CayMedia can help your business rise to the next level through our tried and tested Search Engine Optimization. We will look at your business’s website to find out what SEO services best apply so that you can achieve growth.',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
        desc: 'When it comes to content, it is all about when the business may have to face content conversion. Working with our experts will help you reduce risks, lower conversion timelines and handle information migration safely. We are here to offer your business top-notch solutions. Call us today or visit our office and meet our team of experts. They are ready to listen and structure the right solution for your immediate needs.',
      },
    ]
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Discovery',
    desc: '1Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  }, {
    lable: '',
    title: 'Discovery2',
    desc: '2Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  },{
    lable: '',
    title: 'Discovery3',
    desc: '3Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  },{
    lable: '',
    title: 'Discovery4',
    desc: '4Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  }],
}, 
{
  mainFeaturedPost : {
    tagline: 'Digital Marketing',
    preTitle: 'Digital Marketing is at the core of business development, spanning all areas of commerce.',
    description:
      "Caymedia is expert at implementing digital strategies to match your demands. While consumers have become increasingly dependent on digital electronics, we at Caymedia, have been working hard to create Digital Marketing strategies to align with such trends. Using the Internet’s creativity to increase customer interaction is the only conceivable way forward towards business success.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Our Process',
    title: 'Custom-tailored Digital Marketing Strategy',
    description: 'Extraordinary digital experiences are our thing. Creating a Digital Marketing Strategy to optimize your company’s objectives is our ultimate goal.',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
      },
    ],
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Marketing Dynamics',
    desc: 'Marketing dynamics have changed significantly in the last few decades. Compared to a traditional marketing approach, your customer audience becomes limitless with the establishment of a powerful Digital Marketing strategy.',
  }, {
    lable: '',
    title: 'Market Research',
    desc: 'Using online media channels to endorse brands, goods and services is the ‘new normal’ in today’s technological, modern world. Caymedia conducts in-depth market research to define and measure your customer base and goals.',
  },{
    lable: '',
    title: 'Marketing Tools',
    desc: 'Email, websites, social media, search platforms, mobile apps…these are just a few of the potential Digital Marketing tools used to raise company awareness.',
  },{
    lable: '',
    title: 'Future',
    desc: 'The future of your company depends largely on the successful implementation of Caymedia’s Digital Marketing strategies and the ability to connect with your online customers.',
  }],
}, 
{
  mainFeaturedPost : {
    tagline: 'Social Media Management',
    preTitle: 'Managing your Social Media channels can easily become overwhelming in today’s digital marketplace.',
    description:
      "Caymedia takes care of all that, so you don’t have to. Nowadays, the business potential of Social Media Networking is limitless. No matter your industry, the optimization of these tools is crucial to your success. Here, at Caymedia, we aim to increase your company’s exposure along with your products and services’ branding, thus allowing you to focus on meeting your goals more effectively.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Our Process',
    title: 'Custom-tailored Social Media Management',
    description: 'Let us be the lifeline between you and your customer by defining the necessary Social Media tools that fit your business.',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
      },
    ]
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Development',
    desc: 'Knowing your target audience is key in developing sustainable Social Media networks. Increasing the visibility of your website and expanding SEO rankings ultimately leads to a boost in both your traffic and sales.',
  }, {
    lable: '',
    title: 'Content',
    desc: 'Simply posting content which lacks depth or credibility will create a negative impact amongst your user base. Originality, relevance and reliability are much more likely to result in repetitive patterns of positive customer behavior.',
  },{
    lable: '',
    title: 'Engagement',
    desc: 'Engaging with your customer through Social Media platforms such as Facebook, Twitter, Instagram, Tik-Tok offers a cost-effective, profitable pipeline and maximizes your business potential.',
  },{
    lable: '',
    title: 'Results',
    desc: 'Advertising your business and services, increasing your brand awareness, maximizing your sales, exploring opportunities for growth and connecting with your customers, are all key outcomes of a successful Social Media Management Strategy.',
  }],
}, 
{
  mainFeaturedPost : {
    tagline: 'Ecommerce',
    preTitle: 'The digital era is here to stay, so get on board',
    description:
      "Caymedia’s vision to develop your eCommerce presence. Ecommerce markets are growing at a dynamic rate worldwide. Smooth and swift clicks from product to checkout mean increased sales and elevated customer satisfaction. Driven by today’s technological advances, and as a leader in Digital Business, Caymedia can assist you with all your eCommerce needs.",
    image: `${process.env.REACT_APP_IMG}/v1641023647/CayMedia/team.png`,
    actions: [{ 
      btn: 'View our all work',
      hash: true,
      http: false,
      form: false,
      url: '/about#ourTeam',
    },
    {
      btn: 'See more about us',
      hash: false,
      url: '/portfolio',
    }],
    inner: true,
    portfolio: false,
  },
  category: {
    preTitle: 'Our Process',
    title: 'Custom-tailored Ecommerce Strategy',
    description: 'Establishing a successful Internet presence is the fundamental basis for business growth in today’s digital environment.',
  }, 
  services: {
    head: false,
    tiers: [
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550497/CayMedia/Digital.svg`,
        title: 'Digital Marketing Services',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550513/CayMedia/SEO.svg`,
        title: 'SEO Audit and Technical SEO',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550487/CayMedia/Data.svg`,
        title: 'Long-term Data Analysis',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550478/CayMedia/Customer.svg`,
        title: 'Customer Engagement',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550505/CayMedia/Keyword.svg`,
        title: 'Keyword Research and Strategy',
      },
      {
        icon: `${process.env.REACT_APP_IMG}/v1641550414/CayMedia/Content.svg`,
        title: 'Content Conversion',
      },
    ]
  },
  testimonial : {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
      img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
      role: 'Kathryn, CEO',
      desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
  }, 
  teams:[
    {img : `${process.env.REACT_APP_IMG}/v1641551874/CayMedia/team1.jpg`},
    {img : `${process.env.REACT_APP_IMG}/v1641551978/CayMedia/team2.jpg`},
  ],
  steps: [{
    lable: '',
    title: 'Online Presence',
    desc: 'The convenience of online shopping has evolved the way business is done and the pandemic exposed the fragility of those companies out of the loop. Growth of your business relies heavily on appropriate online commerce.',
  }, {
    lable: '',
    title: 'Customer Experience',
    desc: 'In order to sell your products and services online, you must first connect with your customers. This means initially capturing their attention, then maintaining their interest and finally ensuring a smooth transaction process. Caymedia has the tools for this.',
  },{
    lable: '',
    title: 'Customer Monitoring',
    desc: 'Ecommerce tools are an effective way of monitoring customer behavior. By analyzing the data available from these models, we can learn more about customer trends, profiles and desires, ultimately leading to increased sales and profits.',
  },{
    lable: '',
    title: 'Optimization',
    desc: 'After experiencing an unprecedented growth of eCommerce during the Covid-19 pandemic, it has become apparent that consumers habits have changed dramatically. It is, therefore, essential that your company also aligns with this trend and maximizes profitability.',
  }],
}];

const useStyles = makeStyles((theme) => ({
  mainBg :{
      margin: 0,
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640604608/CayMedia/bg-2.jpg) repeat-x 0 0`,
      transition: 'background 0.3s ease',
      [theme.breakpoints.down('xl')]: {
        backgroundPosition: '0px -13em',
      },
  },
}));

const Service = ({id}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  useEffect(() => {
    document.body.classList.add(`${classes.mainBg}`);
  }, [dispatch, classes.mainBg]);

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <ProductCTA post={post[id].mainFeaturedPost}/>
        {/* <CategoryHead post={post[id].category} />
         <HorizontalLinearStepper post={post[id].steps} /> */}
        <HowStarted post={howStarted}/>
        <WhatWeDo post={post[id].services} />
        <Group group={post[id].teams} />
        <Testimonial post={post[id].testimonial} />
      </Grid>
    </Container>
  );
};

export default Service;
