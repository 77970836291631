import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    flex: 1,
  },
  sidebar: {
    '& link:':{
      '&$order': {
        display: 'flex',
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  heading: {
    color: 'rgba(0,183,255, 1)',
    textDecoration: 'none',
  },
  image: {
    marginLeft: '15px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '24px',
  },
  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  hide:{
    display: 'none',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  link: {
    '&$order': {
      padding: theme.spacing(1, 2),
      background: 'transparent',
      color: '#202020',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(3),
        fontWeight: '700',
        fontFamily: `"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif`,
        textAlign: 'center',
        textTransform: 'uppercase',
        '&$child': {
          textTransform: 'none',
          fontWeight: '500',
          fontSize: theme.spacing(2.5),
        }
      }
    }
  },
  order: {},
  child: {},
  quote: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
}));
