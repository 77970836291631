import React,{useState, useEffect, useRef, createRef} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../Common/Button';
import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  headline: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: "46px", 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    marginBottom: theme.spacing(6),
    position: "relative",
    width: '50%',
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#fff", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "0", 
    left: "0", 
    zIndex: "0", 
    borderRadius: "5px"
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    '&$order': {
      width:'100px',
      padding: theme.spacing(2, 3, 0),
    },
  },
  order: {},
  card: {
    '&$order': {
      background: 'transparent',
      border: '3px solid #000',
      minHeight: '311px',
    },
  },
  btnBase: {
    borderBottom: '5px solid #07AFBB',
  },
  img: {
    width: `calc(100% - ${theme.spacing(4)})`,
    padding: theme.spacing(2),
  },
  imgBg: {
    borderRadius: '10px',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '900',
    fontSize: theme.spacing(4)
  },
  marBot: {
    '&$order': {
      marginBottom: theme.spacing(10),
    }
  },
  anc: {
    textDecoration: 'none',
  }
  // project:{
  //   height: '200vh',
  // },
}));

const GcBtn = ({card, idx}) => {  
  const classes = useStyles();

  return (
    <>
    {idx===1 ?
    <Grid item xs={12} md={5} sx={{ alignSelf: 'center', pl: 2}} className={`${classes.marBot} ${classes.order}`} >
      <CardContent sx={{ flexGrow: 1 }} className={classes.btnBase}>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {card.imgTitle}
        </Typography>
        <Typography variant="body1">
          {card.imgDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <a className={classes.anc} href={card.url} target="_blank">
        <Button sx={{color: '#fff', borderColor: '#fff'}} size="medium" variant="outlined">
          {card.buttonText}
        </Button></a>
      </CardActions>
    </Grid> :
    <Grid item xs={12} md={7} className={`${classes.flexCenter} ${classes.marBot} ${classes.order}`} >
      <Box className={classes.imgBg} sx={{background: `${card.bg}`}}>
        <Box component="img" src={card.image} alt={card.imgTitle} className={classes.img} />
      </Box>
    </Grid>}
    {idx===1 ?
    <Grid item xs={12} md={7} className={`${classes.flexCenter} ${classes.marBot} ${classes.order}`} >
      <Box className={classes.imgBg} sx={{background: `${card.bg}`}}>
        <Box component="img" src={card.image} alt={card.imgTitle} className={classes.img} />
      </Box>
    </Grid> :
    <Grid item xs={12} md={5} sx={{ alignSelf: 'center', pl: 5}} className={`${classes.marBot} ${classes.order}`} >
      <CardContent sx={{ flexGrow: 1 }} className={classes.btnBase}>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {card.imgTitle}
        </Typography>
        <Typography variant="body1">
          {card.imgDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <a className={classes.anc} href={card.url} target="_blank">
        <Button sx={{color: '#fff', borderColor: '#fff'}} size="medium" variant="outlined">
        {card.buttonText}</Button></a>
      </CardActions>
    </Grid>}
    </>
  )
}

function OurProjects({post, setBg}) {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const classes = useStyles();
  const bg = (b, i) => {
    gsap.to(el.current, {background: `${b}`});
    //gsap.set(q(`.${classes.img}`), {transformOrigin: "center"});
    //gsap.set(q(`.${classes.img}`),{attr:{src:i.image}});
    
  }

  //const sections = document.querySelectorAll("section");

  // this scrolling object just allows us to conveniently call scrolling.enable(), scrolling.disable(), and check if scrolling.enabled is true. 
  // some browsers (like iOS Safari) handle scrolling on a separate thread and can cause things to get out of sync (jitter/jumpy), so when we're animating the scroll position, force an update of GSAP tweens when there's a scroll event in order to maintain synchronization)
  // const scrolling = {
  //     enabled: true,
  //     events: "scroll,wheel,touchmove,pointermove".split(","),
  //     prevent: e => e.preventDefault(),
  //     disable() {
  //       if (scrolling.enabled) {
  //         scrolling.enabled = false;
  //         window.addEventListener("scroll", gsap.ticker.tick, {passive: true});
  //         scrolling.events.forEach((e, i) => (i ? document : window).addEventListener(e, scrolling.prevent, {passive: false}));
  //       }
  //     },
  //     enable() {
  //       if (!scrolling.enabled) {
  //         scrolling.enabled = true;
  //         window.removeEventListener("scroll", gsap.ticker.tick);
  //         scrolling.events.forEach((e, i) => (i ? document : window).removeEventListener(e, scrolling.prevent));
  //       }
  //     }
  //   };


  // function goToSection(section, anim, i) {
  //   if (scrolling.enabled) { // skip if a scroll tween is in progress
  //     scrolling.disable();
  //     gsap.to(window, {
  //       scrollTo: {y: section, autoKill: false},
  //       onComplete: scrolling.enable,
  //       duration: 1
  //     });

  //     anim && anim.restart();
  //   }
  // }

  // sections.forEach((section, i) => {
  //   const intoAnim = gsap.from(section.querySelector(".right-col"), {yPercent: 50, duration: 1, paused: true});
    
  //   ScrollTrigger.create({
  //     trigger: section,
  //     start: "top bottom-=1",
  //     end: "bottom top+=1",
  //     onEnter: () => goToSection(section, intoAnim),
  //     onEnterBack: () => goToSection(section)
  //   });
  
  // });

  //************************************************************************************************ *//

  // useEffect(() => {
  //   let hgt = el.current.clientHeight;
  //   let gcToggle = true;
  //   let ptl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: `.${classes.project}`,
  //       toggleActions: "restart pause reverse pause",
  //       start: '50 top',
  //       end: `${hgt} 90%`,
  //       markers: true,
  //       scrub: true,
  //       duration: 1,
  //       pin: `.${classes.project}`,
  //       //onEnter: () => gsap.to(el.current, {background:'linear-gradient(246.37deg, #0547DD 11.08%, #5A8DFF 125.4%)'}),
  //       //onLeave: () => gsap.to(el.current, {backgroundColor:'#07AFBB'}),
  //       onLeaveBack: () => bg('#07AFBB',post.projects[0]),
  //       onUpdate: (s) => {
  //         let progress = s.progress.toFixed(3);
  //         if(progress<0.420 && gcToggle){
  //           gcToggle = false;
  //           bg('linear-gradient(246.37deg, #0547DD 11.08%, #5A8DFF 125.4%)',post.projects[0]);
  //         } else if(progress>0.420 && progress<0.620){
  //           bg('#FFBA34',post.projects[1]);
  //         } else if(progress>0.620){
  //           bg('linear-gradient(246.37deg, #E9903B 11.08%, #E9903B 125.4%)',post.projects[2]);
  //         }
  //       },
  //       //onEnterBack: () => gsap.to(el.current, {backgroundColor:"#07AFBB"}),
  //     },
  //   });

  //  tl.current = ptl.to(q(`.${classes.highlighter}`), {x: 100}, 0)
  //    .to(q(`.content`), { duration: 1, y: -`${hgt}`}, 0)
  //   return () => {
  //     ScrollTrigger.kill();
  //   };
  // },[]);

  return (
    <Container ref={el} maxWidth={false} className={classes.project} component="section" sx={{background: `#07AFBB url(${process.env.REACT_APP_IMG}/v1640608564/CayMedia/bg-star.png) center`,display: 'flex', pt: 8, color: '#fff', overflow: 'hidden'}}>
      <Container component="section" sx={{display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} flexDirection='column'>
          <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
              {post.preTitle}</Typography>
          <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
            {post.title}&nbsp;
            <div className={classes.highlighter}>{post.highlight}<span className={classes.highlight} /></div>
            </Typography>
          <Typography component="p" variant="h5" align="center">{post.description}</Typography>
        </Grid>
        <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column'>
            <Grid container>
              {post.projects.map((card, i) => (
                  <GcBtn key={card.imgTitle} card={card} idx={i} />
              ))}
            </Grid>
        </Grid>
        <Grid item xs={12} flexDirection='column' sx={{m: '0 0 56px', textAlign: 'center' }}>
          <Button to="/portfolio" component={Link}  sx={{color: '#fff', borderColor: '#fff'}} size="medium" variant="outlined">see our all works</Button>
        </Grid>
      </Grid>
      </Container>
    </Container>
  );
}

export default OurProjects;