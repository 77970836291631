import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  Typography,
  makeStyles, styled
} from "@material-ui/core";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
  stepNum: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: theme.spacing(18.75), 
    position: "relative",
    lineHeight: theme.spacing(18.75), 
    color: 'rgba(5, 187, 199, 0.2)',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '900',
    fontSize: theme.spacing(3)
  },
  stepDetails: {
    display: 'flex',
    position: 'relative',
    left: 0,
    '&$step2': {
      left: '29%',
    },
    '&$step3': {
      left: '60%',
    },
    '&$step4': {
      left: '68%',
    }
  },
  step2: {},
  step3: {},
  step4: {},
  
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#08afbb',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#08afbb',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderWidth: 1,
    borderRadius: 1,
    borderStyle: 'dashed',
  },
}));
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#07AFBB',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const HorizontalLinearStepper = ({post}) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const selectedStep = (e,i) => {
    setActiveStep(i);
  };

  return (
    <Container disableGutters component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={10}>
          <Stepper activeStep={activeStep} connector={<QontoConnector />}>
            {post.map((step, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption"></Typography>
                );
              }
              return (
                <Step key={step.lable} {...stepProps} onMouseEnter={(e)=>selectedStep(e,index)}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{step.lable}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep !== post.length && (
            <>
              <Grid container justifyContent='flex-start' flexDirection='row' alignItems='flex-start'>
                <Grid item xs={12} md={4} className={activeStep ? activeStep===2 ? `${classes.stepDetails} ${classes.step3}` : activeStep===3 ? `${classes.stepDetails} ${classes.step4}` : `${classes.stepDetails} ${classes.step2}` : classes.stepDetails}>
                  <Typography variant="h2" component="h2" className={classes.stepNum}>{activeStep + 1}</Typography>
                  <Box sx={{ display: 'flex',pt: 2, flexDirection: 'column'}}>
                    <Typography variant="h2" component="h2" className={classes.title} >{post[activeStep].title}</Typography>
                    <Typography variant="body2">{post[activeStep].desc}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default HorizontalLinearStepper;