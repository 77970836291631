import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from './Navbar';
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    background: 'transparent',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: '100%',
    background: 'rgb(255 255 255 / 90%)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    margin: theme.spacing(4.35, 2, 0, 'auto'), 
  },
  topBar: {
    minHeight: theme.spacing(16),
    display: 'flex',
  },
  ico: {
    height: '1.5em',
    width: '1.5em',
  }
}));

const ResponsiveDrawer = () => {
const classes = useStyles();
const theme = useTheme();
const smBrUp = useMediaQuery(theme.breakpoints.up('md'));
const [mobileOpen, setMobileOpen] = useState(false);
function handleDrawerToggle() {
  setMobileOpen(!mobileOpen)
}

return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar} color='inherit'>
        <Toolbar disableGutters className={classes.topBar}>
          <Navbar sidebar={false} toggleClose={()=>{}} mobileOpen={mobileOpen}/>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge='end'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon className={classes.ico}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {!smBrUp &&
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{paper: classes.drawerPaper,}}
            ModalProps={{keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon className={classes.ico}/>
            </IconButton>
            <Navbar sidebar={true} toggleClose={handleDrawerToggle} mobileOpen={mobileOpen}/> 
          </Drawer>
        }
      </nav>
    </div>
  );
}

export default React.memo(ResponsiveDrawer);