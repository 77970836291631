import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
  noMar: {
    '&$order&$root': {
      margin:0,
      background: '#fff',
    }
  },
  order: {},
  root: {},
}));

const Input = ({ name, value, defaultValue,InputProps,  placeholder, error, helperText, handleChange, rows, multiline, label, half, autoFocus, type, required, handleShowPassword }) => {
  const cls = useStyles();
  return (
  <Grid item xs={12} sm={half ? 6 : 12}>
    <TextField
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      variant="outlined"
      autoComplete="off"
      rows={rows}
      value={value}
      defaultValue={defaultValue}
      multiline={multiline}
      required={required}
      fullWidth
      label={label}
      autoFocus={autoFocus}
      type={type}
      InputProps={InputProps ? InputProps : name === 'password' ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {type === 'password' ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ), classes: {root: ''}} 
      : name === 'confirmPassword' ? {
        endAdornment: (
        <InputAdornment position="end">
          <IconButton >
            {!error ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}
          </IconButton>
        </InputAdornment>
      ), classes: {root: ''}} 
      : { classes: {root: ''}}}
      classes={{root: `${cls.noMar} ${cls.order} ${cls.root}`}}
    />
  </Grid>
)};

export default React.memo(Input);