import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Avatar } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import Button from '../Common/Button';
//import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import * as actionType from '../../constants/actionTypes';
import useStyles from './styles';

const SubNav = [{
  title: 'Marketing Strategy',
  path: '/Marketing_Strategy',
},{
  title: 'Brand Design & Development',
  path: '/Brand_Design_Development',
},{
  title: 'Website Design & Development',
  path: '/Website_Design_Development',
},{
  title: 'Digital Marketing',
  path: '/Digital_Marketing',
},{
  title: 'Social Media Management',
  path: '/Social_Media_Management',
},{
  title: 'Ecommerce',
  path: '/Ecommerce',
}
]

const Navbar = ({sidebar, toggleClose, mobileOpen}) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [expand, setExpand] = useState(false);
  const mobileClick = () => {
    setExpand(!expand);
  };

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    history.push('/auth');
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  return (
    <AppBar className={classes.appBar} elevation={0} position="static" color="inherit">
      <div className={!sidebar ? classes.brandContainer : classes.hide}>
        <ButtonBase disableRipple to="/" component={Link}><img className={classes.image} src={`${process.env.REACT_APP_IMG}/v1637221659/CayMedia/logo.png`} alt="icon" height='56' /></ButtonBase>
      </div>
      <div className={sidebar ? classes.sidebar : classes.quote}>
          <Button to="/" component={Link} onClick={toggleClose} className={`${classes.link} ${classes.order}`}>Home</Button>
          <Button to="/about" component={Link} onClick={toggleClose} className={`${classes.link} ${classes.order}`}>About</Button>
          { mobileOpen ?
          <List component="nav">
            <ListItemButton onClick={mobileClick}>
              <ListItemText classes = {{
                primary: `${classes.link} ${classes.order}`
              }}>Services {expand ? <ExpandLess /> : <ExpandMore />}</ListItemText>
            </ListItemButton>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {SubNav.map((anc) => (
                  <ListItemButton key={`mob-${anc.title}`} to={anc.path} component={Link} onClick={toggleClose}>
                    <ListItemText primary={anc.title} classes = {{
                      primary: `${classes.link} ${classes.order} ${classes.child}`
                    }}/>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
          :
          <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={mobileOpen ? ()=>{} : handleClick}
            onMouseOver={mobileOpen ? ()=>{} : handleClick}
            className={`${classes.link} ${classes.order}`}
          >Services</Button>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{'aria-labelledby': 'basic-button',}}>
              {SubNav.map((anc) => (
                <MenuItem key={`dt-${anc.title}`} to={anc.path} component={Link} onClick={handleClose}>{anc.title}</MenuItem>  
              ))}
            </Menu>
          </>
          }
          <Button to="/portfolio" onClick={toggleClose} component={Link} className={`${classes.link} ${classes.order}`}>Portfolio</Button>
          <Button to="/contact" onClick={toggleClose} component={Link} className={`${classes.link} ${classes.order}`}>Contact</Button>
      </div>
      <Toolbar disableGutters className={`${classes.toolbar}  ${classes.quote}`}>
        {user?.result ? (
          <div className={classes.profile}>
            <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>
            <Typography className={classes.userName} variant="h6">{user?.result.name}</Typography>
            <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
          </div>
        ) : (
          !sidebar && <Button component={Link} to="/contact" variant="contained" color="primary">Get in touch today</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
