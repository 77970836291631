import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import useStyles from "../Common/styles";

const GcMember = ({member}) => {
    const classes = useStyles();
    return (
        <>
        <Box  className={classes.mem}>
            <Box className={`${classes.backTile} ${classes.inner}`}/>
            <Box component="img" src={member.img} alt={member.name} className={classes.img}/>
        </Box>
        <Typography variant="h2" component="h2" className={classes.name} >
        {member.name}</Typography>
        <Typography variant="h2" component="h2" className={classes.desig} gutterBottom>
        {member.desig}</Typography>
        </>
    )
}

const OurTeam = ({post}) => {
  const classes = useStyles();

  return (
    <Container id='ourTeam' disableGutters component="section" sx={{ pt: 8, display: 'flex', pb: 6 }}>
        <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column'>
            <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
                {post.preTitle}</Typography>
            <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
            {post.title}&nbsp;
            </Typography>
            <Grid container spacing={2.5}>
            {post.members.map((member) => (
            <Grid item key={member.name} xs={12} sm={6} md={4}>
                <GcMember member={member}/>
            </Grid>
            ))}
            </Grid>
        </Grid>
    </Container>
  );
};

export default OurTeam;