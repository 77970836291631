import { EMAIL } from '../constants/actionTypes';
import * as api from '../api/index.js';

// export const email = (formData) => async (dispatch) => {
//   try {
//     const { data } = await api.email(formData);
//     dispatch({ type: EMAIL, data });
//     //router.push('/');
//   } catch (error) {
//     console.log(error);
//   }
// };

// Action Creators
export const email = (formData) => async (dispatch) => {
  try {
      const { data } = await api.email(formData);
      dispatch({ type: EMAIL, payload: data});
  } catch (error) {
      console.log(error);
  }
}