import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '../Common/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    backgroundImage: `url(${process.env.REACT_APP_IMG}/v1636110075/Proservices/home.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    animation: `$shrink 36s linear infinite 0s`,
  },
  headline: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: "46px", 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    marginBottom: theme.spacing(2),
    position: "relative",
    textTransform: "uppercase",
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#07AFBB", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "0", 
    left: "0", 
    zIndex: "0", 
    borderRadius: "5px"
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
  },
  margin: {
    margin: theme.spacing(1),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  howStartedPostContent: {
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
    background: '#fff',
    color: '#000',
    margin: theme.spacing(15,0,-0.25,10),
  },
  mainFeaturedPostRoot:{
    minHeight: `calc(100vh - ${theme.spacing(16)})`,
    alignItems: 'center',
  },
  '@keyframes shrink': {
    '0%': { 
      backgroundPosition: 'top right',
      animationTimingFunction: 'ease-in',
    },
    '50%': { 
      backgroundPosition: 'bottom left',
      animationTimingFunction: 'ease-out', 
    },
    '100%': { 
      backgroundPosition: 'top right',
      animationTimingFunction: 'ease-in',
    },
  },  
}));

const HowStarted = (props) => {
  const classes = useStyles();
  const { post } = props;

  return (
    <Container maxWidth='lg'>
      <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})`, margin: '80px auto'}}>
        {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
        <div className={classes.overlay} />
        <Grid container className={classes.mainFeaturedPostRoot}>
          <Grid item md={8}>
            <div className={classes.howStartedPostContent}>
            <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
                    {post.tagline}<span className={classes.tagHighlight}></span></Typography>
              <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
                <div className={classes.highlighter}>{post.title}<span className={classes.highlight} /></div>
              </Typography>
              <Typography component="p" variant="body2" color="inherit" gutterBottom paragraph>
                {post.description}
              </Typography>
              <Typography component="p" variant="body2" color="inherit" gutterBottom paragraph>
                {post.descriptionTwo}
              </Typography>
              {post.descriptionThree && <Typography component="p" variant="body2" color="inherit" gutterBottom paragraph>
                {post.descriptionThree}
              </Typography>}
              {post.btn &&
              <Button
                to="/about" component={Link}
                color="primary"
                variant="text"
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                {post.buttonText}
              </Button> }
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

HowStarted.propTypes = {
  post: PropTypes.object,
};

export default React.memo(HowStarted);