import React, {useEffect} from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductCTA from '../Home/ProductCTA';
import OurProjects from '../Home/OurProjects';
import Testimonial from '../Service/Testimonial';

const mainFeaturedPost = {
  tagline: 'OUR WORK',
  preTitle: 'We know our work deeply',
  title: '',
  description:
    "We cultivate expertise in every stage of product creation, resulting in extraordinary digital experiences. We live our core values to better serve our clients.",
  image: `${process.env.REACT_APP_IMG}/v1641885433/CayMedia/Portfolio.png`,
  actions: [{ 
    btn: 'Get Started',
    hash: false,
    http: false,
    form: false,
    url: '/contact',
  }],
  inner: false,
  portfolio: true,
};

const whatwedo = {
  head: true,
  preTitle: 'What We Do',
  title: 'WE HELP',
  highlight: 'BUSINESSES THRIVE',
  description: 'We are a creative marketing agency that helps brands to achieve their business outcomes. We see technology as a tool to help others succeed.',
  tiers: [
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640092570/CayMedia/Marketing%20Strategy.svg',
      title: 'Marketing Strategy',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Marketing_Strategy',
    },
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640086602/CayMedia/Brand%20Desig%20Development.svg',
      title: 'Brand Design & Development',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Brand_Design_Development',
    },
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640092612/CayMedia/Website%20DesignDevelopment.svg',
      title: 'Website Design & Development',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Website_Design_Development',
    },
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640092494/CayMedia/Digital%20Marketing.svg',
      title: 'Digital Marketing',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Digital_Marketing',
    },
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640092591/CayMedia/Social%20Media%20Management.svg',
      title: 'Social Media Management',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Social_Media_Management',
    },
    {
      icon: 'https://res.cloudinary.com/dmg2giy20/image/upload/v1640092549/CayMedia/Ecommerce.svg',
      title: 'Ecommerce',
      description: 'We design specific graphics for your every need. Flyers, business cards, brochures, you name it we design it!',
      path: 'Ecommerce',
    },
  ]
};

const ourProjects = {
  preTitle: 'Our Projects',
  title: 'We Help Bring Your Brands',
  highlight: 'Story To Life',
  projects: [
    {
      image: `${process.env.REACT_APP_IMG}/v1640171092/CayMedia/Website%20Design.png`,
      bg: 'linear-gradient(246.37deg, #0547DD 11.08%, #5A8DFF 125.4%)',
      imgTitle: 'Website Design',
      imgDescription: 'Check out our website design portfolio and see we how can help your business to grow!',
      buttonText: 'Visit Website',
    },
    {
      image: `${process.env.REACT_APP_IMG}/v1640149570/CayMedia/Branding%20Portfolio.png`,
      bg: '#FFBA34',
      imgTitle: 'Branding Portfolio',
      imgDescription: 'View our branding portfolio and see how our company can help yours!',
      buttonText: 'Visit Website',
    },
    {
      image: `${process.env.REACT_APP_IMG}/v1640172321/CayMedia/Graphic%20Designs.png`,
      bg: 'linear-gradient(246.37deg, #E9903B 11.08%, #E9903B 125.4%)',
      imgTitle: 'Graphic Designs',
      imgDescription: 'We design graphics for any occassion, contact us today!',
      buttonText: 'Visit Website',
    },
  ]
};

const howWeDoIt = {
  preTitle: 'How We Do It',
  title: 'OUR',
  highlight: 'PROCESS',
  buttonText: 'Get started today',
  steps: [
    {
      imgTitle: 'STRATEGY',
      imgDescription: 'We define your competition and target audience. Discover what is working in your online industry, then design your campaign strategy accordingly.',
      
    },
    {
      imgTitle: 'DESIGN',
      imgDescription: 'Color scheme, layout, font, and style. We will bring your marketing ideas to life with a one of a kind masterpiece, built just for you.',
    },
    {
      imgTitle: 'DEVELOPMENT',
      imgDescription: 'This is where we turn your ideas into a reality, while keeping you updated every step of the way.',
    },
    {
      imgTitle: 'CUSTOMER SUPPORT',
      imgDescription: 'This is where you go live, to the world. Design, marketing, and maintenance; we\'ll be at your side for the life of your business.',
    },
  ]
};

const howStarted = {
  tagline: 'How it all started',
  title: 'ABOUT US',
  description:
    "As your outsourced Marketing Agency, we’re here to be part of your team. We’re shifting the marketing paradigm by putting client success ahead of our own. We tailor our business around helping yours, providing you with comprehensive, à la carte services and month-to-month contracts to suit your every need.",
  descriptionTwo:
    "Being a remote agency means we have many offices in many locations. It means we have access to people and resources outside our own doors. It also means we can service many clients around the region. We work digitally and stay connected through our network system to make sure your needs are met.",
  image: `${process.env.REACT_APP_IMG}/v1640246255/CayMedia/about.jpg`,
  buttonText: 'Learn more about Us',
  btn: true,
};

const clientsWhoTrust = {
  tagline: 'Clients who trust us',
  title: 'BRANDS',
  highlight: 'WE WORK WITH',
  description:
    "We've Worked With Them Now Trust Us To Do The Same For You",
  image: `${process.env.REACT_APP_IMG}/v1640256693/CayMedia/brands.png`,
  brands:[
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613553/CayMedia/step.jpg`,
    },
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613428/CayMedia/ciota.jpg`,
    },
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613480/CayMedia/fcal.jpg`,
    },
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613532/CayMedia/mkw.jpg`,
    },
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613576/CayMedia/tryden.jpg`,
    },
    {
    imgUrl: `${process.env.REACT_APP_IMG}/v1640613503/CayMedia/lift%20studio.jpg`,
    },
  ]
};

const testimonial = {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
        img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
        role: 'Kathryn, CEO',
        desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
        img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
        role: 'Kathryn, CEO',
        desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
};


const useStyles = makeStyles((theme) => ({
  mainBg :{
      margin: 0,
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640604608/CayMedia/bg-2.jpg) repeat-x 0 0`,
      transition: 'background 0.3s ease',
      [theme.breakpoints.down('xl')]: {
        backgroundPosition: '0px -13em',
      },
  },
  section: {
    position: 'relative',
    background: '#fff',
  }
}));

const Portfolio = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add(`${classes.mainBg}`);
  })

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <ProductCTA post={mainFeaturedPost}/>
        <OurProjects post={ourProjects} />
        <Testimonial post={testimonial} />
      </Grid>
    </Container>
  );
};

export default Portfolio;