import React, { useState, useEffect } from "react";
import { Paper, TextField, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {Grid} from '@material-ui/core';
import Button from '../Common/Button';
import useStyles from "./styles";
import Input from "../Common/Input";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import { email } from "../../actions/email";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import AlertDialog from "../Common/AlertDialog";

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
      code: 'AU',
      label: 'Australia',
      phone: '61',
      suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
      code: 'CA',
      label: 'Canada',
      phone: '1',
      suggested: true,
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      phone: '61',
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      phone: '243',
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      phone: '236',
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
      code: 'DE',
      label: 'Germany',
      phone: '49',
      suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
      code: 'DO',
      label: 'Dominican Republic',
      phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      phone: '500',
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
      code: 'FR',
      label: 'France',
      phone: '33',
      suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
      code: 'IO',
      label: 'British Indian Ocean Territory',
      phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
      code: 'IR',
      label: 'Iran, Islamic Republic of',
      phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
      code: 'JP',
      label: 'Japan',
      phone: '81',
      suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
      code: 'KN',
      label: 'Saint Kitts and Nevis',
      phone: '1-869',
    },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
      code: 'LA',
      label: "Lao People's Democratic Republic",
      phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
      code: 'MD',
      label: 'Moldova, Republic of',
      phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
      code: 'MF',
      label: 'Saint Martin (French part)',
      phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
      code: 'MP',
      label: 'Northern Mariana Islands',
      phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
      code: 'PM',
      label: 'Saint Pierre and Miquelon',
      phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
      code: 'PS',
      label: 'Palestine, State of',
      phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
      code: 'SJ',
      label: 'Svalbard and Jan Mayen',
      phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
      code: 'ST',
      label: 'Sao Tome and Principe',
      phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
      code: 'SX',
      label: 'Sint Maarten (Dutch part)',
      phone: '1-721',
    },
    {
      code: 'SY',
      label: 'Syrian Arab Republic',
      phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
      code: 'TC',
      label: 'Turks and Caicos Islands',
      phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
      code: 'TF',
      label: 'French Southern Territories',
      phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
      code: 'TT',
      label: 'Trinidad and Tobago',
      phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
      code: 'TW',
      label: 'Taiwan, Province of China',
      phone: '886',
    },
    {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
      code: 'US',
      label: 'United States',
      phone: '1',
      suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
      code: 'VA',
      label: 'Holy See (Vatican City State)',
      phone: '379',
    },
    {
      code: 'VC',
      label: 'Saint Vincent and the Grenadines',
      phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
      code: 'VG',
      label: 'British Virgin Islands',
      phone: '1-284',
    },
    {
      code: 'VI',
      label: 'US Virgin Islands',
      phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];


const initQuestionInState = {
  formType: 'questionnaire',
  businessName: "",
  primaryContact: {
    first: '',
    last: ''
  },
  businessAddress: {
    businessAddress: "",
    businessAddressLine2: "",
    city: "",
    region: "",
    postalCode: "",
    country:     [
      {
         code: "AF",
         label: "Afghanistan"
      },
      {
         code: "AL",
         label: "Albania"
      },
      {
         code: "DZ",
         label: "Algeria"
      },
      {
         code: "AS",
         label: "American Samoa"
      },
      {
         code: "AD",
         label: "Andorra"
      },
      {
         code: "AO",
         label: "Angola"
      },
      {
         code: "AI",
         label: "Anguilla"
      },
      {
         code: "AQ",
         label: "Antarctica"
      },
      {
         code: "AG",
         label: "Antigua and Barbuda"
      },
      {
         code: "AR",
         label: "Argentina"
      },
      {
         code: "AM",
         label: "Armenia"
      },
      {
         code: "AW",
         label: "Aruba"
      },
      {
         code: "AU",
         label: "Australia"
      },
      {
         code: "AT",
         label: "Austria"
      },
      {
         code: "AZ",
         label: "Azerbaijan"
      },
      {
         code: "BS",
         label: "Bahamas"
      },
      {
         code: "BH",
         label: "Bahrain"
      },
      {
         code: "BD",
         label: "Bangladesh"
      },
      {
         code: "BB",
         label: "Barbados"
      },
      {
         code: "BY",
         label: "Belarus"
      },
      {
         code: "BE",
         label: "Belgium"
      },
      {
         code: "BZ",
         label: "Belize"
      },
      {
         code: "BJ",
         label: "Benin"
      },
      {
         code: "BM",
         label: "Bermuda"
      },
      {
         code: "BT",
         label: "Bhutan"
      },
      {
         code: "BO",
         label: "Bolivia (Plurinational State of)"
      },
      {
         code: "BQ",
         label: "Bonaire, Saint Eustatius and Saba"
      },
      {
         code: "BA",
         label: "Bosnia and Herzegovina"
      },
      {
         code: "BW",
         label: "Botswana"
      },
      {
         code: "BV",
         label: "Bouvet Island"
      },
      {
         code: "BR",
         label: "Brazil"
      },
      {
         code: "IO",
         label: "British Indian Ocean Territory"
      },
      {
         code: "BN",
         label: "Brunei Darussalam"
      },
      {
         code: "BG",
         label: "Bulgaria"
      },
      {
         code: "BF",
         label: "Burkina Faso"
      },
      {
         code: "BI",
         label: "Burundi"
      },
      {
         code: "CV",
         label: "Cabo Verde"
      },
      {
         code: "KH",
         label: "Cambodia"
      },
      {
         code: "CM",
         label: "Cameroon"
      },
      {
         code: "CA",
         label: "Canada"
      },
      {
         code: "KY",
         label: "Cayman Islands"
      },
      {
         code: "CF",
         label: "Central African Republic"
      },
      {
         code: "TD",
         label: "Chad"
      },
      {
         code: "CL",
         label: "Chile"
      },
      {
         code: "CN",
         label: "China"
      },
      {
         code: "CX",
         label: "Christmas Island"
      },
      {
         code: "CC",
         label: "Cocos (Keeling) Islands"
      },
      {
         code: "CO",
         label: "Colombia"
      },
      {
         code: "KM",
         label: "Comoros"
      },
      {
         code: "CG",
         label: "Congo"
      },
      {
         code: "CD",
         label: "Congo (Democratic Republic of the)"
      },
      {
         code: "CK",
         label: "Cook Islands"
      },
      {
         code: "CR",
         label: "Costa Rica"
      },
      {
         code: "HR",
         label: "Croatia"
      },
      {
         code: "CU",
         label: "Cuba"
      },
      {
         code: "CW",
         label: "Curaçao"
      },
      {
         code: "CY",
         label: "Cyprus"
      },
      {
         code: "CZ",
         label: "Czech Republic"
      },
      {
         code: "CI",
         label: "Côte d'Ivoire"
      },
      {
         code: "DK",
         label: "Denmark"
      },
      {
         code: "DJ",
         label: "Djibouti"
      },
      {
         code: "DM",
         label: "Dominica"
      },
      {
         code: "DO",
         label: "Dominican Republic"
      },
      {
         code: "EC",
         label: "Ecuador"
      },
      {
         code: "EG",
         label: "Egypt"
      },
      {
         code: "SV",
         label: "El Salvador"
      },
      {
         code: "GQ",
         label: "Equatorial Guinea"
      },
      {
         code: "ER",
         label: "Eritrea"
      },
      {
         code: "EE",
         label: "Estonia"
      },
      {
         code: "SZ",
         label: "Eswatini (Kingdom of)"
      },
      {
         code: "ET",
         label: "Ethiopia"
      },
      {
         code: "FK",
         label: "Falkland Islands (Malvinas)"
      },
      {
         code: "FO",
         label: "Faroe Islands"
      },
      {
         code: "FJ",
         label: "Fiji"
      },
      {
         code: "FI",
         label: "Finland"
      },
      {
         code: "FR",
         label: "France"
      },
      {
         code: "GF",
         label: "French Guiana"
      },
      {
         code: "PF",
         label: "French Polynesia"
      },
      {
         code: "TF",
         label: "French Southern Territories"
      },
      {
         code: "GA",
         label: "Gabon"
      },
      {
         code: "GM",
         label: "Gambia"
      },
      {
         code: "GE",
         label: "Georgia"
      },
      {
         code: "DE",
         label: "Germany"
      },
      {
         code: "GH",
         label: "Ghana"
      },
      {
         code: "GI",
         label: "Gibraltar"
      },
      {
         code: "GR",
         label: "Greece"
      },
      {
         code: "GL",
         label: "Greenland"
      },
      {
         code: "GD",
         label: "Grenada"
      },
      {
         code: "GP",
         label: "Guadeloupe"
      },
      {
         code: "GU",
         label: "Guam"
      },
      {
         code: "GT",
         label: "Guatemala"
      },
      {
         code: "GG",
         label: "Guernsey"
      },
      {
         code: "GN",
         label: "Guinea"
      },
      {
         code: "GW",
         label: "Guinea-Bissau"
      },
      {
         code: "GY",
         label: "Guyana"
      },
      {
         code: "HT",
         label: "Haiti"
      },
      {
         code: "HM",
         label: "Heard Island and McDonald Islands"
      },
      {
         code: "HN",
         label: "Honduras"
      },
      {
         code: "HK",
         label: "Hong Kong"
      },
      {
         code: "HU",
         label: "Hungary"
      },
      {
         code: "IS",
         label: "Iceland"
      },
      {
         code: "IN",
         label: "India"
      },
      {
         code: "ID",
         label: "Indonesia"
      },
      {
         code: "IR",
         label: "Iran (Islamic Republic of)"
      },
      {
         code: "IQ",
         label: "Iraq"
      },
      {
         code: "IE",
         label: "Ireland (Republic of)"
      },
      {
         code: "IM",
         label: "Isle of Man"
      },
      {
         code: "IL",
         label: "Israel"
      },
      {
         code: "IT",
         label: "Italy"
      },
      {
         code: "JM",
         label: "Jamaica"
      },
      {
         code: "JP",
         label: "Japan"
      },
      {
         code: "JE",
         label: "Jersey"
      },
      {
         code: "JO",
         label: "Jordan"
      },
      {
         code: "KZ",
         label: "Kazakhstan"
      },
      {
         code: "KE",
         label: "Kenya"
      },
      {
         code: "KI",
         label: "Kiribati"
      },
      {
         code: "KP",
         label: "Korea (Democratic People's Republic of)"
      },
      {
         code: "KR",
         label: "Korea (Republic of)"
      },
      {
         code: "XK",
         label: "Kosovo"
      },
      {
         code: "KW",
         label: "Kuwait"
      },
      {
         code: "KG",
         label: "Kyrgyzstan"
      },
      {
         code: "LA",
         label: "Lao People's Democratic Republic"
      },
      {
         code: "LV",
         label: "Latvia"
      },
      {
         code: "LB",
         label: "Lebanon"
      },
      {
         code: "LS",
         label: "Lesotho"
      },
      {
         code: "LR",
         label: "Liberia"
      },
      {
         code: "LY",
         label: "Libya"
      },
      {
         code: "LI",
         label: "Liechtenstein"
      },
      {
         code: "LT",
         label: "Lithuania"
      },
      {
         code: "LU",
         label: "Luxembourg"
      },
      {
         code: "MO",
         label: "Macao"
      },
      {
         code: "MG",
         label: "Madagascar"
      },
      {
         code: "MW",
         label: "Malawi"
      },
      {
         code: "MY",
         label: "Malaysia"
      },
      {
         code: "MV",
         label: "Maldives"
      },
      {
         code: "ML",
         label: "Mali"
      },
      {
         code: "MT",
         label: "Malta"
      },
      {
         code: "MH",
         label: "Marshall Islands"
      },
      {
         code: "MQ",
         label: "Martinique"
      },
      {
         code: "MR",
         label: "Mauritania"
      },
      {
         code: "MU",
         label: "Mauritius"
      },
      {
         code: "YT",
         label: "Mayotte"
      },
      {
         code: "MX",
         label: "Mexico"
      },
      {
         code: "FM",
         label: "Micronesia (Federated States of)"
      },
      {
         code: "MD",
         label: "Moldova (Republic of)"
      },
      {
         code: "MC",
         label: "Monaco"
      },
      {
         code: "MN",
         label: "Mongolia"
      },
      {
         code: "ME",
         label: "Montenegro"
      },
      {
         code: "MS",
         label: "Montserrat"
      },
      {
         code: "MA",
         label: "Morocco"
      },
      {
         code: "MZ",
         label: "Mozambique"
      },
      {
         code: "MM",
         label: "Myanmar"
      },
      {
         code: "NA",
         label: "Namibia"
      },
      {
         code: "NR",
         label: "Nauru"
      },
      {
         code: "NP",
         label: "Nepal"
      },
      {
         code: "NL",
         label: "Netherlands"
      },
      {
         code: "NC",
         label: "New Caledonia"
      },
      {
         code: "NZ",
         label: "New Zealand"
      },
      {
         code: "NI",
         label: "Nicaragua"
      },
      {
         code: "NE",
         label: "Niger"
      },
      {
         code: "NG",
         label: "Nigeria"
      },
      {
         code: "NU",
         label: "Niue"
      },
      {
         code: "NF",
         label: "Norfolk Island"
      },
      {
         code: "MK",
         label: "North Macedonia (Republic of)"
      },
      {
         code: "MP",
         label: "Northern Mariana Islands"
      },
      {
         code: "NO",
         label: "Norway"
      },
      {
         code: "OM",
         label: "Oman"
      },
      {
         code: "PK",
         label: "Pakistan"
      },
      {
         code: "PW",
         label: "Palau"
      },
      {
         code: "PS",
         label: "Palestine (State of)"
      },
      {
         code: "PA",
         label: "Panama"
      },
      {
         code: "PG",
         label: "Papua New Guinea"
      },
      {
         code: "PY",
         label: "Paraguay"
      },
      {
         code: "PE",
         label: "Peru"
      },
      {
         code: "PH",
         label: "Philippines"
      },
      {
         code: "PN",
         label: "Pitcairn"
      },
      {
         code: "PL",
         label: "Poland"
      },
      {
         code: "PT",
         label: "Portugal"
      },
      {
         code: "PR",
         label: "Puerto Rico"
      },
      {
         code: "QA",
         label: "Qatar"
      },
      {
         code: "RO",
         label: "Romania"
      },
      {
         code: "RU",
         label: "Russian Federation"
      },
      {
         code: "RW",
         label: "Rwanda"
      },
      {
         code: "RE",
         label: "Réunion"
      },
      {
         code: "BL",
         label: "Saint Barthélemy"
      },
      {
         code: "SH",
         label: "Saint Helena, Ascension and Tristan da Cunha"
      },
      {
         code: "KN",
         label: "Saint Kitts and Nevis"
      },
      {
         code: "LC",
         label: "Saint Lucia"
      },
      {
         code: "MF",
         label: "Saint Martin (French part)"
      },
      {
         code: "PM",
         label: "Saint Pierre and Miquelon"
      },
      {
         code: "VC",
         label: "Saint Vincent and the Grenadines"
      },
      {
         code: "WS",
         label: "Samoa"
      },
      {
         code: "SM",
         label: "San Marino"
      },
      {
         code: "ST",
         label: "Sao Tome and Principe"
      },
      {
         code: "SA",
         label: "Saudi Arabia"
      },
      {
         code: "SN",
         label: "Senegal"
      },
      {
         code: "RS",
         label: "Serbia"
      },
      {
         code: "SC",
         label: "Seychelles"
      },
      {
         code: "SL",
         label: "Sierra Leone"
      },
      {
         code: "SG",
         label: "Singapore"
      },
      {
         code: "SX",
         label: "Sint Maarten (Dutch part)"
      },
      {
         code: "SK",
         label: "Slovakia"
      },
      {
         code: "SI",
         label: "Slovenia"
      },
      {
         code: "SB",
         label: "Solomon Islands"
      },
      {
         code: "SO",
         label: "Somalia"
      },
      {
         code: "ZA",
         label: "South Africa"
      },
      {
         code: "GS",
         label: "South Georgia and the South Sandwich Islands"
      },
      {
         code: "SS",
         label: "South Sudan"
      },
      {
         code: "ES",
         label: "Spain"
      },
      {
         code: "LK",
         label: "Sri Lanka"
      },
      {
         code: "SD",
         label: "Sudan"
      },
      {
         code: "SR",
         label: "Suriname"
      },
      {
         code: "SJ",
         label: "Svalbard and Jan Mayen"
      },
      {
         code: "SE",
         label: "Sweden"
      },
      {
         code: "CH",
         label: "Switzerland"
      },
      {
         code: "SY",
         label: "Syrian Arab Republic"
      },
      {
         code: "TW",
         label: "Taiwan, Republic of China"
      },
      {
         code: "TJ",
         label: "Tajikistan"
      },
      {
         code: "TZ",
         label: "Tanzania (United Republic of)"
      },
      {
         code: "TH",
         label: "Thailand"
      },
      {
         code: "TL",
         label: "Timor-Leste"
      },
      {
         code: "TG",
         label: "Togo"
      },
      {
         code: "TK",
         label: "Tokelau"
      },
      {
         code: "TO",
         label: "Tonga"
      },
      {
         code: "TT",
         label: "Trinidad and Tobago"
      },
      {
         code: "TN",
         label: "Tunisia"
      },
      {
         code: "TR",
         label: "Turkey"
      },
      {
         code: "TM",
         label: "Turkmenistan"
      },
      {
         code: "TC",
         label: "Turks and Caicos Islands"
      },
      {
         code: "TV",
         label: "Tuvalu"
      },
      {
         code: "UG",
         label: "Uganda"
      },
      {
         code: "UA",
         label: "Ukraine"
      },
      {
         code: "AE",
         label: "United Arab Emirates"
      },
      {
         code: "GB",
         label: "United Kingdom of Great Britain and Northern Ireland"
      },
      {
         code: "UM",
         label: "United States Minor Outlying Islands"
      },
      {
         code: "US",
         label: "United States of America"
      },
      {
         code: "UY",
         label: "Uruguay"
      },
      {
         code: "UZ",
         label: "Uzbekistan"
      },
      {
         code: "VU",
         label: "Vanuatu"
      },
      {
         code: "VA",
         label: "Vatican City State"
      },
      {
         code: "VE",
         label: "Venezuela (Bolivarian Republic of)"
      },
      {
         code: "VN",
         label: "Vietnam"
      },
      {
         code: "VG",
         label: "Virgin Islands (British)"
      },
      {
         code: "VI",
         label: "Virgin Islands (U.S.)"
      },
      {
         code: "WF",
         label: "Wallis and Futuna"
      },
      {
         code: "EH",
         label: "Western Sahara"
      },
      {
         code: "YE",
         label: "Yemen"
      },
      {
         code: "ZM",
         label: "Zambia"
      },
      {
         code: "ZW",
         label: "Zimbabwe"
      },
      {
         code: "AX",
         label: "Åland Islands"
      }
    ],
    selectedCountry: "",
    phoneNumber: [
      {
          label: "Afghanistan (‫افغانستان‬‎)",
          dialCode: "+93"
      },
      {
          label: "Albania (Shqipëri)",
          dialCode: "+355"
      },
      {
          label: "Algeria (‫الجزائر‬‎)",
          dialCode: "+213"
      },
      {
          label: "American Samoa",
          dialCode: "+1"
      },
      {
          label: "Andorra",
          dialCode: "+376"
      },
      {
          label: "Angola",
          dialCode: "+244"
      },
      {
          label: "Anguilla",
          dialCode: "+1"
      },
      {
          label: "Antigua and Barbuda",
          dialCode: "+1"
      },
      {
          label: "Argentina",
          dialCode: "+54"
      },
      {
          label: "Armenia (Հայաստան)",
          dialCode: "+374"
      },
      {
          label: "Aruba",
          dialCode: "+297"
      },
      {
          label: "Australia",
          dialCode: "+61"
      },
      {
          label: "Austria (Österreich)",
          dialCode: "+43"
      },
      {
          label: "Azerbaijan (Azərbaycan)",
          dialCode: "+994"
      },
      {
          label: "Bahamas",
          dialCode: "+1"
      },
      {
          label: "Bahrain (‫البحرين‬‎)",
          dialCode: "+973"
      },
      {
          label: "Bangladesh (বাংলাদেশ)",
          dialCode: "+880"
      },
      {
          label: "Barbados",
          dialCode: "+1"
      },
      {
          label: "Belarus (Беларусь)",
          dialCode: "+375"
      },
      {
          label: "Belgium (België)",
          dialCode: "+32"
      },
      {
          label: "Belize",
          dialCode: "+501"
      },
      {
          label: "Benin (Bénin)",
          dialCode: "+229"
      },
      {
          label: "Bermuda",
          dialCode: "+1"
      },
      {
          label: "Bhutan (འབྲུག)",
          dialCode: "+975"
      },
      {
          label: "Bolivia",
          dialCode: "+591"
      },
      {
          label: "Bosnia and Herzegovina (Босна и Херцеговина)",
          dialCode: "+387"
      },
      {
          label: "Botswana",
          dialCode: "+267"
      },
      {
          label: "Brazil (Brasil)",
          dialCode: "+55"
      },
      {
          label: "British Indian Ocean Territory",
          dialCode: "+246"
      },
      {
          label: "British Virgin Islands",
          dialCode: "+1"
      },
      {
          label: "Brunei",
          dialCode: "+673"
      },
      {
          label: "Bulgaria (България)",
          dialCode: "+359"
      },
      {
          label: "Burkina Faso",
          dialCode: "+226"
      },
      {
          label: "Burundi (Uburundi)",
          dialCode: "+257"
      },
      {
          label: "Cambodia (កម្ពុជា)",
          dialCode: "+855"
      },
      {
          label: "Cameroon (Cameroun)",
          dialCode: "+237"
      },
      {
          label: "Canada",
          dialCode: "+1"
      },
      {
          label: "Cape Verde (Kabu Verdi)",
          dialCode: "+238"
      },
      {
          label: "Caribbean Netherlands",
          dialCode: "+599"
      },
      {
          label: "Cayman Islands",
          dialCode: "+1"
      },
      {
          label: "Central African Republic (République centrafricaine)",
          dialCode: "+236"
      },
      {
          label: "Chad (Tchad)",
          dialCode: "+235"
      },
      {
          label: "Chile",
          dialCode: "+56"
      },
      {
          label: "China (中国)",
          dialCode: "+86"
      },
      {
          label: "Christmas Island",
          dialCode: "+61"
      },
      {
          label: "Cocos (Keeling) Islands",
          dialCode: "+61"
      },
      {
          label: "Colombia",
          dialCode: "+57"
      },
      {
          label: "Comoros (‫جزر القمر‬‎)",
          dialCode: "+269"
      },
      {
          label: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
          dialCode: "+243"
      },
      {
          label: "Congo (Republic) (Congo-Brazzaville)",
          dialCode: "+242"
      },
      {
          label: "Cook Islands",
          dialCode: "+682"
      },
      {
          label: "Costa Rica",
          dialCode: "+506"
      },
      {
          label: "Côte d’Ivoire",
          dialCode: "+225"
      },
      {
          label: "Croatia (Hrvatska)",
          dialCode: "+385"
      },
      {
          label: "Cuba",
          dialCode: "+53"
      },
      {
          label: "Curaçao",
          dialCode: "+599"
      },
      {
          label: "Cyprus (Κύπρος)",
          dialCode: "+357"
      },
      {
          label: "Czech Republic (Česká republika)",
          dialCode: "+420"
      },
      {
          label: "Denmark (Danmark)",
          dialCode: "+45"
      },
      {
          label: "Djibouti",
          dialCode: "+253"
      },
      {
          label: "Dominica",
          dialCode: "+1"
      },
      {
          label: "Dominican Republic (República Dominicana)",
          dialCode: "+1"
      },
      {
          label: "Ecuador",
          dialCode: "+593"
      },
      {
          label: "Egypt (‫مصر‬‎)",
          dialCode: "+20"
      },
      {
          label: "El Salvador",
          dialCode: "+503"
      },
      {
          label: "Equatorial Guinea (Guinea Ecuatorial)",
          dialCode: "+240"
      },
      {
          label: "Eritrea",
          dialCode: "+291"
      },
      {
          label: "Estonia (Eesti)",
          dialCode: "+372"
      },
      {
          label: "Ethiopia",
          dialCode: "+251"
      },
      {
          label: "Falkland Islands (Islas Malvinas)",
          dialCode: "+500"
      },
      {
          label: "Faroe Islands (Føroyar)",
          dialCode: "+298"
      },
      {
          label: "Fiji",
          dialCode: "+679"
      },
      {
          label: "Finland (Suomi)",
          dialCode: "+358"
      },
      {
          label: "France",
          dialCode: "+33"
      },
      {
          label: "French Guiana (Guyane française)",
          dialCode: "+594"
      },
      {
          label: "French Polynesia (Polynésie française)",
          dialCode: "+689"
      },
      {
          label: "Gabon",
          dialCode: "+241"
      },
      {
          label: "Gambia",
          dialCode: "+220"
      },
      {
          label: "Georgia (საქართველო)",
          dialCode: "+995"
      },
      {
          label: "Germany (Deutschland)",
          dialCode: "+49"
      },
      {
          label: "Ghana (Gaana)",
          dialCode: "+233"
      },
      {
          label: "Gibraltar",
          dialCode: "+350"
      },
      {
          label: "Greece (Ελλάδα)",
          dialCode: "+30"
      },
      {
          label: "Greenland (Kalaallit Nunaat)",
          dialCode: "+299"
      },
      {
          label: "Grenada",
          dialCode: "+1"
      },
      {
          label: "Guadeloupe",
          dialCode: "+590"
      },
      {
          label: "Guam",
          dialCode: "+1"
      },
      {
          label: "Guatemala",
          dialCode: "+502"
      },
      {
          label: "Guernsey",
          dialCode: "+44"
      },
      {
          label: "Guinea (Guinée)",
          dialCode: "+224"
      },
      {
          label: "Guinea-Bissau (Guiné Bissau)",
          dialCode: "+245"
      },
      {
          label: "Guyana",
          dialCode: "+592"
      },
      {
          label: "Haiti",
          dialCode: "+509"
      },
      {
          label: "Honduras",
          dialCode: "+504"
      },
      {
          label: "Hong Kong (香港)",
          dialCode: "+852"
      },
      {
          label: "Hungary (Magyarország)",
          dialCode: "+36"
      },
      {
          label: "Iceland (Ísland)",
          dialCode: "+354"
      },
      {
          label: "India (भारत)",
          dialCode: "+91"
      },
      {
          label: "Indonesia",
          dialCode: "+62"
      },
      {
          label: "Iran (‫ایران‬‎)",
          dialCode: "+98"
      },
      {
          label: "Iraq (‫العراق‬‎)",
          dialCode: "+964"
      },
      {
          label: "Ireland",
          dialCode: "+353"
      },
      {
          label: "Isle of Man",
          dialCode: "+44"
      },
      {
          label: "Israel (‫ישראל‬‎)",
          dialCode: "+972"
      },
      {
          label: "Italy (Italia)",
          dialCode: "+39"
      },
      {
          label: "Jamaica",
          dialCode: "+1"
      },
      {
          label: "Japan (日本)",
          dialCode: "+81"
      },
      {
          label: "Jersey",
          dialCode: "+44"
      },
      {
          label: "Jordan (‫الأردن‬‎)",
          dialCode: "+962"
      },
      {
          label: "Kazakhstan (Казахстан)",
          dialCode: "+7"
      },
      {
          label: "Kenya",
          dialCode: "+254"
      },
      {
          label: "Kiribati",
          dialCode: "+686"
      },
      {
          label: "Kosovo",
          dialCode: "+383"
      },
      {
          label: "Kuwait (‫الكويت‬‎)",
          dialCode: "+965"
      },
      {
          label: "Kyrgyzstan (Кыргызстан)",
          dialCode: "+996"
      },
      {
          label: "Laos (ລາວ)",
          dialCode: "+856"
      },
      {
          label: "Latvia (Latvija)",
          dialCode: "+371"
      },
      {
          label: "Lebanon (‫لبنان‬‎)",
          dialCode: "+961"
      },
      {
          label: "Lesotho",
          dialCode: "+266"
      },
      {
          label: "Liberia",
          dialCode: "+231"
      },
      {
          label: "Libya (‫ليبيا‬‎)",
          dialCode: "+218"
      },
      {
          label: "Liechtenstein",
          dialCode: "+423"
      },
      {
          label: "Lithuania (Lietuva)",
          dialCode: "+370"
      },
      {
          label: "Luxembourg",
          dialCode: "+352"
      },
      {
          label: "Macau (澳門)",
          dialCode: "+853"
      },
      {
          label: "Macedonia (FYROM) (Македонија)",
          dialCode: "+389"
      },
      {
          label: "Madagascar (Madagasikara)",
          dialCode: "+261"
      },
      {
          label: "Malawi",
          dialCode: "+265"
      },
      {
          label: "Malaysia",
          dialCode: "+60"
      },
      {
          label: "Maldives",
          dialCode: "+960"
      },
      {
          label: "Mali",
          dialCode: "+223"
      },
      {
          label: "Malta",
          dialCode: "+356"
      },
      {
          label: "Marshall Islands",
          dialCode: "+692"
      },
      {
          label: "Martinique",
          dialCode: "+596"
      },
      {
          label: "Mauritania (‫موريتانيا‬‎)",
          dialCode: "+222"
      },
      {
          label: "Mauritius (Moris)",
          dialCode: "+230"
      },
      {
          label: "Mayotte",
          dialCode: "+262"
      },
      {
          label: "Mexico (México)",
          dialCode: "+52"
      },
      {
          label: "Micronesia",
          dialCode: "+691"
      },
      {
          label: "Moldova (Republica Moldova)",
          dialCode: "+373"
      },
      {
          label: "Monaco",
          dialCode: "+377"
      },
      {
          label: "Mongolia (Монгол)",
          dialCode: "+976"
      },
      {
          label: "Montenegro (Crna Gora)",
          dialCode: "+382"
      },
      {
          label: "Montserrat",
          dialCode: "+1"
      },
      {
          label: "Morocco (‫المغرب‬‎)",
          dialCode: "+212"
      },
      {
          label: "Mozambique (Moçambique)",
          dialCode: "+258"
      },
      {
          label: "Myanmar (Burma) (မြန်မာ)",
          dialCode: "+95"
      },
      {
          label: "Namibia (Namibië)",
          dialCode: "+264"
      },
      {
          label: "Nauru",
          dialCode: "+674"
      },
      {
          label: "Nepal (नेपाल)",
          dialCode: "+977"
      },
      {
          label: "Netherlands (Nederland)",
          dialCode: "+31"
      },
      {
          label: "New Caledonia (Nouvelle-Calédonie)",
          dialCode: "+687"
      },
      {
          label: "New Zealand",
          dialCode: "+64"
      },
      {
          label: "Nicaragua",
          dialCode: "+505"
      },
      {
          label: "Niger (Nijar)",
          dialCode: "+227"
      },
      {
          label: "Nigeria",
          dialCode: "+234"
      },
      {
          label: "Niue",
          dialCode: "+683"
      },
      {
          label: "Norfolk Island",
          dialCode: "+672"
      },
      {
          label: "North Korea (조선 민주주의 인민 공화국)",
          dialCode: "+850"
      },
      {
          label: "Northern Mariana Islands",
          dialCode: "+1"
      },
      {
          label: "Norway (Norge)",
          dialCode: "+47"
      },
      {
          label: "Oman (‫عُمان‬‎)",
          dialCode: "+"
      },
      {
          label: "Pakistan (‫پاکستان‬‎)",
          dialCode: "+92"
      },
      {
          label: "Palau",
          dialCode: "+680"
      },
      {
          label: "Palestine (‫فلسطين‬‎)",
          dialCode: "+970"
      },
      {
          label: "Panama (Panamá)",
          dialCode: "+507"
      },
      {
          label: "Papua New Guinea",
          dialCode: "+675"
      },
      {
          label: "Paraguay",
          dialCode: "+595"
      },
      {
          label: "Peru (Perú)",
          dialCode: "+51"
      },
      {
          label: "Philippines",
          dialCode: "+63"
      },
      {
          label: "Poland (Polska)",
          dialCode: "+48"
      },
      {
          label: "Portugal",
          dialCode: "+351"
      },
      {
          label: "Puerto Rico",
          dialCode: "+1"
      },
      {
          label: "Qatar (‫قطر‬‎)",
          dialCode: "+974"
      },
      {
          label: "Réunion (La Réunion)",
          dialCode: "+262"
      },
      {
          label: "Romania (România)",
          dialCode: "+40"
      },
      {
          label: "Russia (Россия)",
          dialCode: "+7"
      },
      {
          label: "Rwanda",
          dialCode: "+250"
      },
      {
          label: "Saint Barthélemy",
          dialCode: "+590"
      },
      {
          label: "Saint Helena",
          dialCode: "+290"
      },
      {
          label: "Saint Kitts and Nevis",
          dialCode: "+1"
      },
      {
          label: "Saint Lucia",
          dialCode: "+1"
      },
      {
          label: "Saint Martin (Saint-Martin (partie française))",
          dialCode: "+590"
      },
      {
          label: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
          dialCode: "+508"
      },
      {
          label: "Saint Vincent and the Grenadines",
          dialCode: "+1"
      },
      {
          label: "Samoa",
          dialCode: "+685"
      },
      {
          label: "San Marino",
          dialCode: "+378"
      },
      {
          label: "São Tomé and Príncipe (São Tomé e Príncipe)",
          dialCode: "+239"
      },
      {
          label: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
          dialCode: "+966"
      },
      {
          label: "Senegal (Sénégal)",
          dialCode: "+221"
      },
      {
          label: "Serbia (Србија)",
          dialCode: "+381"
      },
      {
          label: "Seychelles",
          dialCode: "+248"
      },
      {
          label: "Sierra Leone",
          dialCode: "+232"
      },
      {
          label: "Singapore",
          dialCode: "+65"
      },
      {
          label: "Sint Maarten",
          dialCode: "+1"
      },
      {
          label: "Slovakia (Slovensko)",
          dialCode: "+421"
      },
      {
          label: "Slovenia (Slovenija)",
          dialCode: "+386"
      },
      {
          label: "Solomon Islands",
          dialCode: "+677"
      },
      {
          label: "Somalia (Soomaaliya)",
          dialCode: "+252"
      },
      {
          label: "South Africa",
          dialCode: "+27"
      },
      {
          label: "South Korea (대한민국)",
          dialCode: "+82"
      },
      {
          label: "South Sudan (‫جنوب السودان‬‎)",
          dialCode: "+211"
      },
      {
          label: "Spain (España)",
          dialCode: "+34"
      },
      {
          label: "Sri Lanka (ශ්‍රී ලංකාව)",
          dialCode: "+94"
      },
      {
          label: "Sudan (‫السودان‬‎)",
          dialCode: "+249"
      },
      {
          label: "Suriname",
          dialCode: "+597"
      },
      {
          label: "Svalbard and Jan Mayen",
          dialCode: "+47"
      },
      {
          label: "Swaziland",
          dialCode: "+268"
      },
      {
          label: "Sweden (Sverige)",
          dialCode: "+46"
      },
      {
          label: "Switzerland (Schweiz)",
          dialCode: "+41"
      },
      {
          label: "Syria (‫سوريا‬‎)",
          dialCode: "+963"
      },
      {
          label: "Taiwan (台灣)",
          dialCode: "+886"
      },
      {
          label: "Tajikistan",
          dialCode: "+992"
      },
      {
          label: "Tanzania",
          dialCode: "+255"
      },
      {
          label: "Thailand (ไทย)",
          dialCode: "+66"
      },
      {
          label: "Timor-Leste",
          dialCode: "+670"
      },
      {
          label: "Togo",
          dialCode: "+228"
      },
      {
          label: "Tokelau",
          dialCode: "+690"
      },
      {
          label: "Tonga",
          dialCode: "+676"
      },
      {
          label: "Trinidad and Tobago",
          dialCode: "+1"
      },
      {
          label: "Tunisia (‫تونس‬‎)",
          dialCode: "+216"
      },
      {
          label: "Turkey (Türkiye)",
          dialCode: "+90"
      },
      {
          label: "Turkmenistan",
          dialCode: "+993"
      },
      {
          label: "Turks and Caicos Islands",
          dialCode: "+1"
      },
      {
          label: "Tuvalu",
          dialCode: "+688"
      },
      {
          label: "U.S. Virgin Islands",
          dialCode: "+1"
      },
      {
          label: "Uganda",
          dialCode: "+256"
      },
      {
          label: "Ukraine (Україна)",
          dialCode: "+380"
      },
      {
          label: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
          dialCode: "+971"
      },
      {
          label: "United Kingdom",
          dialCode: "+44"
      },
      {
          label: "United States",
          dialCode: "+1"
      },
      {
          label: "Uruguay",
          dialCode: "+598"
      },
      {
          label: "Uzbekistan (Oʻzbekiston)",
          dialCode: "+998"
      },
      {
          label: "Vanuatu",
          dialCode: "+678"
      },
      {
          label: "Vatican City (Città del Vaticano)",
          dialCode: "+39"
      },
      {
          label: "Venezuela",
          dialCode: "+58"
      },
      {
          label: "Vietnam (Việt Nam)",
          dialCode: "+84"
      },
      {
          label: "Wallis and Futuna (Wallis-et-Futuna)",
          dialCode: "+681"
      },
      {
          label: "Western Sahara (‫الصحراء الغربية‬‎)",
          dialCode: "+212"
      },
      {
          label: "Yemen (‫اليمن‬‎)",
          dialCode: "+967"
      },
      {
          label: "Zambia",
          dialCode: "+260"
      },
      {
          label: "Zimbabwe",
          dialCode: "+263"
      },
      {
          label: "Åland Islands",
          dialCode: "+358"
      }
    ],
    selectedPhone: "",
  },
  email: "",
  currentUrl: "",
  currentWebHost: "",
  dns: "",
  Questions: [
    {
      ques:'Please describe your organization in a few sentences.',
      ans: "",
      multi: true,
      radio: false,
      check: false,
    },
    {
      ques:'Describe the desired look and feel by using adjectives and short phrases.',
      ans: "",
      multi: true,
      radio: false,
      check: false,
    },
    {
      ques:'What is your tagline?',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques:'Do you have a high-resolution logo?',
      ans: 'I need one created',
      multi: false,
      radio: true,
      check: false,
      opts: [{
         o: 'I need one created'
      },{
         o: 'Yes'
      },{
         o: 'No'
      }],
      selectet: [],
    },
    {
      ques:'Are there any colors that you want incorporated into the website?',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques:'If you were using a search engine, what words or phrases would you use to find your site?',
      ans:"",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'Any existing websites you would like to use as inspiration? Please list them & describe what elements you like.',
      ans:"",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'What types of things do you see on other websites do you hate?',
      ans:"",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'Do you currently have content that you want to use?',
      ans:'No',
      multi: false,
      radio: true,
      check: false,
      opts: [{
         o: 'No'
      },{
         o: 'Yes'
      }]
    },
    {
      ques: 'Who will be updating this website on an ongoing basis? Check all that apply.',
      ans: 'CayMedia',
      multi: false,
      radio: true,
      check: false,
      opts: [{
         o: 'CayMedia'
      },{
         o: 'Just me'
      },{
         o: 'Other Staff'
      }]
    },
    {
      ques: 'Approximately many static pages do you need? (Exam: About, Contact Us, Donate, FAQ, etc.)A ballpark number is fine.',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'How often do you think you will be updating the website?',
      ans: 'Monthly',
      multi: false,
      radio: true,
      check: false,
      opts: [{
         o: 'Monthly'
      },{
         o: 'Once a week or more'
      },{
         o: 'Quarterly'
      },{
         o: 'Less than 4 times a year'
      }]
    },
    {
      ques: 'Which Social Media tools do you use? Please list below.',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'Are there any other website functions that you need?',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'What is your preferred deadline for completing the site?',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'Anything else we can help you with?',
      ans: "",
      multi: false,
      radio: false,
      check: false,
    },
    {
      ques: 'Will you require Social Media Management?',
      ans: 'Yes',
      multi: false,
      radio: true,
      check: false,
      opts: [{
         o: 'Yes'
      },{
         o: 'No'
      }]
    }
  ]
};

const QuestionForm = () => {
  const [questionForm, setQuestionForm] = useState(initQuestionInState);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const userForm = (n, v, p, i) => {
    if(initQuestionInState.hasOwnProperty(n) && typeof(initQuestionInState[n]) === 'string') {
      setQuestionForm({ ...questionForm, [n]: v });
    } else if(initQuestionInState.hasOwnProperty(n) && typeof(initQuestionInState[n]) === 'object' && p.length){
      if(i >= 0 && initQuestionInState[n][i].hasOwnProperty('ques') && initQuestionInState[n][i].ques === p){
         setQuestionForm(
            { ...questionForm, [n]: 
               { ...questionForm[n], [i]: 
                  { ...questionForm[n][i], ['ans']: v }
               }
            }
         );
      } else {
         setQuestionForm({ ...questionForm, [n]: { ...questionForm[n], [p]: v}});
      }
    }
  }

  const handleChange = (e, p, i) => {
   userForm(e.target.name, e.target.value, p, i);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit', questionForm);
    setOpen(true);
    dispatch(email(questionForm));
  };

  const handleAuto = (e, v, n, t) => { 
   if(v){
      setCode(v.phone);
      userForm(n, v.label, t);
   }
  };

  return (
    <Paper className={classes.paper}>
      <AlertDialog 
        fire={open} 
        title="Thank you!" 
        desc="Your Questionaire has been received." 
        act1=""
        act2="Go to Home"
      />
      <form
        autoComplete="off"
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
        elevation={2}
      >
        <Grid container spacing={3} justifyContent='center'>
         <Input
            name="businessName"
            variant="outlined"
            label="Business Name"
            fullWidth
            required
            handleChange={handleChange}
            /> 
         <Input
          name="primaryContact"
          variant="outlined"
          label="First Name"
          half
          required
          handleChange={e => handleChange(e, 'first')}
        />
        <Input
          name="primaryContact"
          variant="outlined"
          label="Last Name"
          half
          required
          handleChange={e => handleChange(e, 'last')}
        />
        <Input
          name="businessAddress"
          variant="outlined"
          label="Business Address"
          fullWidth
          required
          handleChange={e => handleChange(e, 'businessAddress')}
        />
        <Input
          name="businessAddress"
          variant="outlined"
          label="Business Address Line 2"
          fullWidth
          required
          handleChange={e => handleChange(e, 'businessAddressLine2')}
        />
        <Input
          name="businessAddress"
          variant="outlined"
          label="City"
          half
          required
          handleChange={e => handleChange(e, 'city')}
        />
        <Input
          name="businessAddress"
          variant="outlined"
          label="State / Province / Region"
          half
          required
          handleChange={e => handleChange(e, 'region')}
        />
        <Grid item xs={12} sm={6}>
         <Autocomplete
            id="country-select"
            sx={{width: '100%'}}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(e, v) => handleAuto(e, v, "businessAddress", "selectedCountry")}
            classes={{ inputRoot: ''}}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                />
                &nbsp;{option.label} ({option.code}) +{option.phone}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Choose a country"
                variant="outlined"
                inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 16 },
                    autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  }
                }}
                classes={{root: `${classes.noMar} ${classes.order}`}}
                />
            )}
            />
         </Grid>
        <Input
          name="businessAddress"
          type="number"
          variant="outlined"
          label="Phone Number"
          InputProps={{
            inputMode: 'numeric', 
            pattern: '[0-9]*',
            startAdornment: <InputAdornment position="start">{`+${code}`}</InputAdornment>,
          }}
          half
          required
          handleChange={e => handleChange(e, 'selectedPhone')}
        />
        <Input
          name="email"
          variant="outlined"
          label="Email Address"
          half
          required
          handleChange={handleChange}
        />
        <Input
          name="currentUrl"
          variant="outlined"
          label="Current Website URL (if applicable)"
          half
          required
          handleChange={handleChange}
        />
        <Input
          name="currentWebHost"
          variant="outlined"
          label="Current Web Hosting Company (if applicable)"
          half
          
          handleChange={handleChange}
        />
        <Input
          name="dns"
          variant="outlined"
          label="What domain registrar do you use? (if applicable)"
          half
          handleChange={handleChange}
        />
         {initQuestionInState.Questions.map((faq, idx) => (
            <>
            {!faq.radio && !faq.check && <Input
               key={faq.ques}
               name="Questions"
               variant="outlined"
               label={faq.ques}
               fullWidth
               multiline
               required
               rows={faq.multi ? 4 : 1}
               handleChange={e => handleChange(e, faq.ques, idx)}
            />}
            {faq.radio && 
               <Grid item xs={12} key={faq.ques}>
                  <FormControl classes={{root: `${classes.radio} ${classes.order}`}}>
                     <FormLabel id="demo-radio-buttons-group-label">{faq.ques}</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={faq.opts[0].o}
                        name="Questions"
                        onChange={e => handleChange(e, faq.ques, idx)}
                     >
                        {faq.opts.map((o, i) => (
                           <FormControlLabel value={o.o} control={<Radio />} label={o.o} />
                        ))}
                     </RadioGroup>
                  </FormControl>
               </Grid>}
               {faq.check && <Grid item xs={12} key={faq.ques}>
                  <FormControl classes={{root: `${classes.radio} ${classes.order}`}} component="fieldset" variant="standard">
                     <FormLabel component="legend">{faq.ques}</FormLabel>
                     <FormGroup
                        name="Questions"
                        onChange={e => handleChange(e, "Questions", faq.ques, idx)}
                     >
                        {faq.opts.map(o => (
                           <FormControlLabel
                              key={o.o}
                              control={
                              <Checkbox name={o.o} />
                              }
                              label={o.o}
                           />
                        ))}
                     </FormGroup>
                  </FormControl>
               </Grid>}
            </>
         ))}
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >Get a free quote&nbsp;<ArrowForwardIcon /></Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default QuestionForm;