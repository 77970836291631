import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../Common/Button';
import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  headline: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: "46px", 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    marginBottom: theme.spacing(2),
    position: "relative",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#07AFBB", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "0", 
    left: "0", 
    zIndex: "0", 
    borderRadius: "5px"
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    '&$order': {
      width:'100px',
      padding: theme.spacing(2, 3, 0),
    },
  },
  order: {},
  card: {
    '&$order': {
      background: 'transparent',
      border: '3px solid #000',
      minHeight: '301px',
    },
  },
  btnBase: {
    borderBottom: '5px solid #07AFBB',
  },
  img: {
    width: `calc(100% - ${theme.spacing(4)})`,
    padding: theme.spacing(2),
  },
  imgBg: {
    borderRadius: '10px',
    width: '80%',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '900',
    fontSize: theme.spacing(3)
  },
  bodr: {
    '&$order': {
      border: '2px solid #202030',
      height: '100%',
    }
  },
  step: {
    fontSize: theme.spacing(10),
    color: '#fff',
    fontWeight: '900',
    textShadow: '0 0 1px #000',
  },
  processBg: {
    position: 'absolute',
    right: '-40px',
    zIndex: '0',
    top: 0,
    bottom: '-64px',
    overflow: 'hidden',
  },
  processPad: {
    padding: theme.spacing(8,0,0),
  }
}));

const GcBtn = ({card, idx}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sx={{ alignSelf: 'center', background: '#fff', position: 'relative', zIndex: '1' }} className={`${classes.bodr} ${classes.order}`} >
      <CardContent sx={{ flexGrow: 1, minHeight: '230px'}}>
        <Typography variant="h4" className={classes.step}>
          {card.count && card.count.length ? card.count : `0${idx+1}`}
        </Typography>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {card.imgTitle}
        </Typography>
        <Typography variant="body2">
          {card.imgDescription}
        </Typography>
      </CardContent>
    </Grid>
  )
}

function HowWeDoIt({post}) {
  const classes = useStyles();

  return (
    <Container component="section"  sx={{position: 'relative',display: 'flex', background: '#fff', mt: 8,mb: 5, color: '#202020', }}>
      <div className={classes.processBg}>
        <img src={`${process.env.REACT_APP_IMG}/v1640609573/CayMedia/process-bg.jpg`} />
      </div>
      <Grid container sx={{alignItems: 'center' }}>
        <Grid item xs={12} md={4} flexDirection='column'>
          <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
              {post.preTitle}</Typography>
          <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
            {post.title}&nbsp;
            <div className={classes.highlighter}>{post.highlight}<span className={classes.highlight} /></div>
            </Typography>
            <Button
              to="/contact" component={Link} 
              type="button"
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
            >{post.buttonText}&nbsp;<ArrowForwardIcon className={`${classes.arrow} ${classes.order}`} /></Button>
        </Grid>
        <Grid item xs={12} md={8} className={classes.flexCenter} flexDirection='column'>
          <Grid container spacing={2} className={classes.processPad}>
            {post.steps.map((card, i) => (
              <Grid item key={card.imgTitle} xs={12} md={6}>
                <GcBtn card={card} idx={i} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HowWeDoIt;