import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const ButtonRoot = styled(MuiButton)(({ theme, size, variant }) => (
  {
  borderRadius: 0,
  fontWeight: '700',
  "fontFamily": `"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif`,
  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(14),
  background: 'linear-gradient(180deg, rgba(7,175,187,1) 0%, rgba(8,154,165,1) 100%)',
  boxShadow: 'none',
  '&:active, &:focus': {
    boxShadow: 'none',
  },
  ...(size === 'small' && {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  }),
  ...(size === 'large' && {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  }),
  ...(variant === 'outlined' && {
    background: 'transparent',
  }),
  ...(variant === 'text' && {
    background: 'transparent',
    paddingLeft: 0,
    color: '#05BBC7',
  }),
  ...(variant === 'gc1' && {
    background: 'transparent',
    borderLeft: '4px solid #07AFBB',
    margin: theme.spacing(1.5,0),
    padding: theme.spacing(1, 2),
    color: '#000',
    display: 'inline-flex',
  }),
}));

// See https://mui.com/guides/typescript/#usage-of-component-prop for why the types uses `C`.
function Button(props) {
  return <ButtonRoot {...props} />;
}

export default Button;