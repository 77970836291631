import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {
Typography,
  makeStyles,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    color: '#A0A0A0',
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  group: {
    height: 500,
  },
  cmt: {
    fontFamily: 'Merriweather',
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: '300',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    textAlign: 'left'
  },
  role: {
    paddingRight: theme.spacing(2),
  }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Testimonial({post}) {
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  return (
    <Container component="section" sx={{display: 'flex', pt: 6 }}>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column' sx={{mt: 0, mb: 8 }}>
            <Grid container justifyContent='center'>
            <Grid item xs={8} style={{background: `url(${post.quotes}) no-repeat`}}>
            <AutoPlaySwipeableViews index={index} onChangeIndex={setIndex} interval={5000}>
                {post.comments.map((cmt, i) => (
                    <Box key={i} sx={{ display: 'flex',pt: 2,pl: 2, flexDirection: 'column'}}>
                        <Typography variant="body2" className={classes.cmt}>{cmt.desc}</Typography>
                        <Typography variant="h2" component="h2" className={classes.title} >
                            <img className={classes.role} src={cmt.img} />{cmt.role}
                        </Typography>
                    </Box>
                ))}
                </AutoPlaySwipeableViews>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Testimonial;