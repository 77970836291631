import React,{useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import CategoryHead from '../Service/CategoryHead';

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    '&$order': {
      width:'70px',
      margin: theme.spacing(5, 3, 1),
    },
  },
  order: {},
  card: {
    '&$order': {
      background: 'transparent',
      border: '3px solid #000',
      minHeight: '311px',
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640607381/CayMedia/card-bg.jpg) no-repeat 0 0`,
    },
  },
  grid: {
    '&$order': {
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000',
      minHeight: '311px',
      background: `transparent`,
      borderRadius: 0,
      alignItems: 'center',
    },
    '&$grid2': {
      borderRight: '1px solid #000',
      borderBottom: 0,
      alignItems: 'center',
    },
    '&$gridLast': {
      borderBottom: '1px solid #000',
      borderRight: 0,
      alignItems: 'center',
      minHeight: '311px',
    },
    '&$gridEnd': {
      alignItems: 'center',
      borderRight: 0,
      minHeight: '311px',
    }
  },
  grid2: {},
  gridLast: {},
  gridEnd: {},
  btnBase: {
    borderBottom: '5px solid #07AFBB',
  },
  cntr: {
    textAlign: 'center',
    maxWidth: theme.spacing(25),
  },
  conHvr: {
    position: 'relative',
    "&:hover": {
      "& $hvr": {
        height: '100%'
      }
    }
  },
  hvr: {
    background: 'rgb(7 175 187 / 95%)',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    width: '100%',
    height: 0,
    transition: '.5s ease',
  },
  txt: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#fff',
    width: '100%'
  }
}));

const GcCard = ({idx, card, head, enter}) => {
  const classes = useStyles();
  return (
    <Card 
      sx={{ height: '100%', display: 'flex', flexDirection: 'column'}} 
      elevation={0}
      className={head ? 
        `${classes.card} ${classes.order}` : 
        idx>=2 ? 
          (idx===2 ? `${classes.grid} ${classes.gridLast}` :
          (idx===5 ? `${classes.grid} ${classes.gridEnd}` :
          `${classes.grid} ${classes.grid2} ${classes.order}`)) : 
          `${classes.grid} ${classes.order}`}>
        <CardMedia
          component="img"
          image={card.icon}
          alt="random"
          classes={{
            img: `${classes.icon} ${classes.order}`
          }}
        />
        <CardContent sx={{ flexGrow: 1, textAlign: 'left' }} className={enter ? classes.btnBase : ''}>
          <Typography gutterBottom variant="h6" component="h5" className={head ? '' : classes.cntr}>
            {card.title}
          </Typography>
          <Typography variant="body2">
            {card.description}
          </Typography>
        </CardContent>
      </Card>
  )
}

const GcBtn = ({card, head, idx}) => {
  const [enter, setEnter] = useState(false);
  const classes = useStyles();
  return (
    head ? 
      <ButtonBase
      focusRipple
      key={card.title}
      className={`${classes.btnBase} ${classes.order}`}
      onMouseEnter={()=>setEnter(true)} onMouseLeave={()=>setEnter(false)}
      component={Link}
      to={`/${card.path}`}
    >
      <GcCard idx={idx} head={head} card={card} enter={enter}/>
    </ButtonBase> : 
    <div className={classes.conHvr}>
      <div className={classes.hvr}>
        <div className={classes.txt}>{card.desc}</div>
      </div>
      <GcCard idx={idx} head={head} card={card} enter={enter}/>
    </div>

  )
}

function WhatWeDo({post}) {
  const classes = useStyles();

  return (
    <Container component="section" sx={{display: 'flex', pt: 11 }}>
      <Grid container>
        {post.head && <CategoryHead post={post} />}
        <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column' sx={{mt: 7, mb: 13}}>
            <Grid container spacing={post.head ? 4 : 0}>
              {post.tiers.map((card, idx) => (
                <Grid item key={card.title} xs={12} sm={6} md={4}>
                  <GcBtn card={card} idx={idx} head={post.head} />
                </Grid>
              ))}
            </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default WhatWeDo;