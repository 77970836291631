import React from 'react';
import {makeStyles,Typography, Grid} from '@material-ui/core';
import Container from '@mui/material/Container';
import ContactForm from './Form';
const useStyles = makeStyles((theme) => ({
    headline: {
        fontStyle: "normal", 
        fontWeight: "900", 
        fontSize: "46px", 
        lineHeight: "121.3%", 
        letterSpacing: "-0.025em", 
        color: "#202020",
        marginBottom: theme.spacing(2),
        position: "relative",
    },
    tagline: {
        fontSize: "17px", 
        fontStyle: "normal", 
        fontWeight: "600", 
        lineHeight: "24px", 
        letterSpacing: "0.188em", 
        textAlign: "left",
        textTransform: "uppercase",
        position: "relative", 
        paddingLeft: "35px"
    },
    highlighter: {
        position: "relative",
        display: 'inline-block',
    },
    highlight: {
        backgroundColor: "#07AFBB", 
        position: "absolute", 
        height: "8px", 
        width: "100%", 
        bottom: "0", 
        left: "0", 
        zIndex: "-1", 
        borderRadius: "5px"
    },
    flexCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backTile: {
        position: "absolute", 
        top: 0, 
        left: 32, 
        right: 0, 
        bottom: 5, 
        width: "80%", 
        background: "#05BBC7", 
        transform: "rotate(\n357deg)",
        borderRadius: theme.spacing(1.5),
        '&$inner': {
            bottom: '35px',
            left: '-40px',
            top: '-20px',
            width: '100%',
        }
    },
    mem: {
        position: 'relative',
    },
    img: {
        width: '80%',
        position: 'relative',
        margin: theme.spacing(0,4),
        maxWidth: 600,
        borderRadius: 2.5,
    },
    name: {
        fontWeight: '500',
        fontSize: theme.spacing(3),
        lineHeight: '154.3%',
        letterSpacing: '0.02em',
        margin: theme.spacing(0,4),
    },
    desig: {
        fontSize: theme.spacing(2),
        lineHeight: '150.3%',
        margin: theme.spacing(0,4),
    }
}));

const GetQuote = ({post}) => {
  const classes = useStyles();

  return (
    <Container disableGutters component="section" sx={{ mt: 0, display: 'flex', pb: 6, justifyContent: 'center' }}>
        <Grid item xs={12} md={8} className={classes.flexCenter} flexDirection='column'>
            <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
                {post.preTitle}</Typography>
            <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
            {post.title}
            </Typography>
            <ContactForm />
        </Grid>
    </Container>
  );
};

export default GetQuote;