import React, {useEffect} from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductCTA from '../Home/ProductCTA';
import Testimonial from '../Service/Testimonial';
import GetQuote from './GetQuote';

const mainFeaturedPost = {
  tagline: 'Contact',
  preTitle: 'Great! We\'re excited to hear from you.',
  description:
    "Are you looking for a digital partner to help with with your digital strategy, UX, web presence or digital marketing activity? Get in touch today and let’s make great things happen.",
image: `${process.env.REACT_APP_IMG}/v1641811708/CayMedia/team3.jpg`,
actions: [{ 
  btn: 'info@caymedia.ky',
  hash: false,
  http: true,
  form: false,
  url: 'mailto:info@caymedia.ky',
},
{
  btn: '1-345-924-8827',
  hash: false,
  http: true,
  url: 'tel:1-345-925-8824',
},
{
  btn: 'Grand Cayman, Cayman Islands',
  hash: false,
  http: true,
  url: 'https://www.google.com/maps/place/Grand+Cayman/@19.3298237,-81.3925165,11z/data=!3m1!4b1!4m5!3m4!1s0x8f258987d79949a1:0x3a0bed0c18902c49!8m2!3d19.3221698!4d-81.2408689',
}],
inner: true,
portfolio: false,
};

const Quote= {
  preTitle: 'get a free quote',
  title: 'Want to start a project?',
  members: [{
    img: `${process.env.REACT_APP_IMG}/v1641816184/CayMedia/Rashad.jpg`,
    name: 'Rashad Rankin',
    desig: 'Founder/CEO - CayMedia'
  },
  {
    img: `${process.env.REACT_APP_IMG}/v1641884404/CayMedia/Kalvin.jpg`,
    name: 'Kalvin Rain',
    desig: 'Web Developer'
  },
  {
    img: `${process.env.REACT_APP_IMG}/v1641884427/CayMedia/Nasha.jpg`,
    name: 'Nasha Henry',
    desig: 'Web Designer'
  }]
};

const testimonial = {
    quotes: `${process.env.REACT_APP_IMG}/v1641557107/CayMedia/Quotes.svg`,
    comments: [{
        img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
        role: 'Kathryn, CEO',
        desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },{
        img: `${process.env.REACT_APP_IMG}/v1641557788/CayMedia/CEO.jpg`,
        role: 'Kathryn, CEO',
        desc: 'Working with Caymedia is like having my own marketing department. The versatility of services they offer provides me with strategy, creativity, and so much more! I would recommend them to anyone interested in measurable marketing... yes I said measurable, it\'s possible.'
    },]
};


const useStyles = makeStyles((theme) => ({
  mainBg :{
      margin: 0,
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640604608/CayMedia/bg-2.jpg) repeat-x 0 0`,
      transition: 'background 0.3s ease',
      [theme.breakpoints.down('xl')]: {
        backgroundPosition: '0px -13em',
      },
  },
  section: {
    position: 'relative',
    background: '#fff',
  }
}));

const Contact = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add(`${classes.mainBg}`);
  })

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <ProductCTA post={mainFeaturedPost}/>
        <GetQuote post={Quote} />
        <Testimonial post={testimonial} />
      </Grid>
    </Container>
  );
};

export default Contact;