import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flexCen: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(7,175,187,1) 0%, rgba(8,154,165,1) 100%)',
    color: '#fff',
    marginBottom: '20px!important'
  }
}));
const AlertDialog = ({fire, title, desc, act1, act2}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setOpen(fire);
  }, [fire]);

  const handleClose = (e, r) => {
    console.log(r);
    if (r && r === "backdropClick" || r && r === 'escapeKeyDown') 
        return;
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle className={classes.flexCen} id="alert-dialog-title">
          <CheckCircleOutlineIcon/>&nbsp;{title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {act1.length>0 && <Button onClick={handleClose}>{act1}</Button>}
          {act2.length>0 && <Button to="/" component={Link} onClick={handleClose} autoFocus>{act2}</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(AlertDialog);