import React, {useEffect} from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductCTA from '../Home/ProductCTA';
import HowWeDoIt from '../Home/HowWeDoIt';
import CategoryHead from '../Service/CategoryHead';
import Group from '../Common/Group';
import OurTeam from './OurTeam';

const mainFeaturedPost = {
  tagline: 'About caymedia',
  preTitle: 'Experts who love to solve problems',
  description: "We cultivate expertise in every stage of product creation, resulting in extraordinary digital experiences. We live our core values to better serve our clients.",
  image: `${process.env.REACT_APP_IMG}/v1641811708/CayMedia/team3.jpg`,
  actions: [{ 
    btn: 'Meet our team',
    hash: true,
    http: false,
    form: false,
    url: '/about#ourTeam',
  },
  {
    btn: 'See our work',
    hash: false,
    url: '/portfolio',
  }],
  inner: true,
  portfolio: false,
};

const category= {
  preTitle: 'OUR History',
  title: 'Creating Digital business Since 2019',
  description: 'Launched back in 2019, Caymedia is a world-class full-app-lifecycle software development company, focusing on building web apps, covering all the segments of a successful product launch and maintenance - ideation, prototyping and design, project management, development, QA testing, and support.',
  description2: 'Today we offer application development services to companies that need to scale up their development capabilities while keeping quality, improving productivity, and optimizing costs.',
};

const whatWeDid = {
  preTitle: 'What we did',
  title: 'Company',
  highlight: 'Facts',
  buttonText: 'Get started today',
  steps: [
    {
      count: '10+',
      imgTitle: 'Full-stack Team',
      imgDescription: 'We define your competition and target audience. Discover what is working in your online industry, then design your campaign strategy accordingly.',
      
    },
    {
      count: '3+',
      imgTitle: 'Years of experience ',
      imgDescription: 'Color scheme, layout, font, and style. We will bring your marketing ideas to life with a one of a kind masterpiece, built just for you.',
    },
    {
      count: '98%',
      imgTitle: 'Customer Rating',
      imgDescription: 'This is where we turn your ideas into a reality, while keeping you updated every step of the way.',
    },
    {
      count: '4X',
      imgTitle: 'Business growth',
      imgDescription: 'This is where you go live, to the world. Design, marketing, and maintenance; we\'ll be at your side for the life of your business.',
    },
  ]
};

const teams = [
  {img : `${process.env.REACT_APP_IMG}/v1641812443/CayMedia/team4.jpg`},
  {img : `${process.env.REACT_APP_IMG}/v1641812597/CayMedia/team5.jpg`},
];

const ourTeam= {
  preTitle: 'our team',
  title: 'Meet Our Team',
  members: [{
    img: `${process.env.REACT_APP_IMG}/v1644584875/CayMedia/Rashad.jpg`,
    name: 'Rashad Rankin',
    desig: 'Founder/CEO - CayMedia'
  },
  {
    img: `${process.env.REACT_APP_IMG}/v1644584770/CayMedia/Jessica.jpg`,
    name: 'Jessica Greaux',
    desig: 'VP of Marketing'
  }]
};

const useStyles = makeStyles((theme) => ({
  mainBg :{
      margin: 0,
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640604608/CayMedia/bg-2.jpg) repeat-x 0 0`,
      transition: 'background 0.3s ease',
      [theme.breakpoints.down('xl')]: {
        backgroundPosition: '0px -13em',
      },
  },
  section: {
    position: 'relative',
    background: '#fff',
  }
}));

const About = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add(`${classes.mainBg}`);
  })

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <ProductCTA post={mainFeaturedPost}/>
        <CategoryHead post={category} />
        <Group group={teams} />
        <HowWeDoIt post={whatWeDid}/>
        <OurTeam post={ourTeam}/>
      </Grid>
    </Container>
  );
};

export default About;