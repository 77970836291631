import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '../Common/Button';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  footerTop: {
    margin: theme.spacing(4, 'auto', 0),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    background: `#02021E url(${process.env.REACT_APP_IMG}/v1640331705/CayMedia/footer-dots.png) no-repeat top left Fixed`, 
    color: '#fff',
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: '#05BBC7',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topNav: {
    flexGrow: '1',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  social: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '130px',
  },
  imgLogo: {
    width: '70%',
  },
  footAbout: {
    margin: theme.spacing(1, 0, 2),
  },
  flogo: {
    width: '100%',
    height: 'auto',
    maxWidth: '180px',
  },
  fItem: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1),
  },
  footLink: {
      "&:hover": {
        textDecoration: 'underline',
      },
      '&$order': {
        color: 'rgba(0, 0, 0, 0.6)',
        textDecoration: 'none', 
    }
  },
  order: {},
  link: {
      margin: theme.spacing(1, 1.5),
      color: '#fff',  
  },
  copyR: {
    fontSize: theme.spacing(2),
  },
  jusCenter: {
    justifyContent: 'center',
    minHeight: theme.spacing(6),
  },
  headline: {
    fontStyle: "normal", 
    fontWeight: "800", 
    fontSize: "46px", 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    color: "#fff",
    marginBottom: theme.spacing(4),
    position: "relative",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
    paddingLeft: "35px"
  },
  tagHighlight: {
    background: "#202020", 
    height: "2px", 
    width: "25px", 
    display: "block", 
    position: "absolute", 
    left: "0", 
    top: "11px"
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#07AFBB", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "0", 
    left: "0", 
    zIndex: "0", 
    borderRadius: "5px"
  },
  footItems: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5.25),
    fontFamily: `"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif`,
    color: '#FFFFFF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.spacing(13),
  }, 
  follow: {
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold',
    margin: theme.spacing(3,0,1),
  },
  footMar: {
    paddingLeft: theme.spacing(2)
  },
  padLft: {
    paddingLeft: theme.spacing(1)
  },
  title: {
    fontWeight: '900',
    fontSize: theme.spacing(3)
  },
}));

const Icn = ({type, idx, pIdx}) => {
  const classes = useStyles();

  return (
    <>
      {type && pIdx === 0 && idx ===0 && <LocalPhoneIcon />}
      {type && pIdx === 0 && idx ===1 && <MarkEmailReadOutlinedIcon />}
      {type && pIdx === 0 && idx ===2 && 
      <>
        <img width="22px" src={`${process.env.REACT_APP_IMG}/v1640340097/CayMedia/whatsapp.svg`} />
      </>}
      {type && pIdx === 0 && idx ===3 && 
      <>
        <img width="22px" src={`${process.env.REACT_APP_IMG}/v1640339944/CayMedia/skype.svg`} />
      </>}
      {type && pIdx === 1 && idx ===0 && 
      <>
        <img width="25px" src={`${process.env.REACT_APP_IMG}/v1640946872/CayMedia/facebook.svg`} />
      </>
      }
      {type && pIdx === 1 && idx ===1 && 
      <>
        <img width="25px" src={`${process.env.REACT_APP_IMG}/v1640946953/CayMedia/Insta.svg`} />
      </>
      }
      {type && pIdx === 1 && idx ===2 && 
      <>
        <img width="25px" src={`${process.env.REACT_APP_IMG}/v1640947185/CayMedia/LinkedIn.svg`} />
      </>
      }
    </>
  )
}

export default function Footer({post}) {
  const css = useStyles();

  return (
    <>
      <Container maxWidth={false} component="footer" className={css.footer}>  
      <Container>
        <Grid container justifyContent="space-evenly" alignItems='center' spacing={10}>
          <Grid item xs={12} md={7}>
            <Typography variant="h2" component="h2" className={css.tagline} gutterBottom>
              {post.tagline}<span className={css.tagHighlight}></span></Typography>
            <Typography variant="h2" component="h2" className={css.headline} gutterBottom>
              {post.title}&nbsp;
              <div className={css.highlighter}>{post.highlight}<span className={css.highlight} /></div>
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>{post.description}</Typography>
            <Typography component="p" variant="body1" gutterBottom>{post.descriptionTwo}</Typography>
            <Button to="/contact" component={Link}  type="button" color="primary" variant="contained" sx={{ mt: 4, mb: 2 }}>
              {post.buttonText}&nbsp;<ArrowForwardIcon className={`${css.arrow} ${css.order}`} /></Button>
          </Grid>
          <Grid item xs={12} md={5} className={css.footMar}>
            {post.contacts.map((v, pi) => (
              <>
                <Typography variant="h2" component="h2" className={pi===1 ? css.follow :css.title} gutterBottom>
                {v.title}</Typography>
                <ul className={pi===1 ? css.inline : ''}>
                    {v.items.map((k, i) => (
                      <li key={`fo-${k.val}`}>
                        <a className={css.footItems} href={`${k.link}`}>
                          <Icn type={k.val} idx={i} pIdx={pi} />{pi===0 && <span className={css.padLft}>{k.val}</span>}
                        </a>
                      </li>
                    ))}
                </ul>
              </>
            ))}
          </Grid>
        </Grid>
      </Container>
      </Container>
      <AppBar position="static" color="default" elevation={0} className={css.appBar}>
        <Toolbar className={css.jusCenter}>
          <Typography variant="h6" color="inherit" noWrap className={css.copyR}>
            © Copyright 2022 All Rights Reserved.
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
