import React from 'react';
import {makeStyles,Typography, Grid} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    headline: {
        fontStyle: "normal", 
        fontWeight: "900", 
        fontSize: "46px", 
        lineHeight: "121.3%", 
        letterSpacing: "-0.025em", 
        color: "#202020",
        marginBottom: theme.spacing(2),
        position: "relative",
    },
    tagline: {
        fontSize: "17px", 
        fontStyle: "normal", 
        fontWeight: "600", 
        lineHeight: "24px", 
        letterSpacing: "0.188em", 
        textAlign: "left",
        textTransform: "uppercase",
        position: "relative", 
        paddingLeft: "35px"
    },
    highlighter: {
        position: "relative",
        display: 'inline-block',
    },
    highlight: {
        backgroundColor: "#07AFBB", 
        position: "absolute", 
        height: "8px", 
        width: "100%", 
        bottom: "0", 
        left: "0", 
        zIndex: "-1", 
        borderRadius: "5px"
    },
    flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
}));
const CategoryHead = ({post}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.flexCenter} flexDirection='column'>
        <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
            {post.preTitle}</Typography>
        <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
        {post.title}&nbsp;
        <div className={classes.highlighter}>{post.highlight}<span className={classes.highlight} /></div>
        </Typography>
        <Grid item xs={12} md={6}>
            <Typography component="p" variant="body1" align="center" gutterBottom>{post.description}</Typography>
        </Grid>
        {post.description2 && <Grid item xs={12} md={6}>
            <Typography component="p" variant="body1" align="center">{post.description2}</Typography>
        </Grid>}
    </Grid>
  );
};

export default CategoryHead;