import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    // '& .MuiTextField-root': {
    //   margin: theme.spacing(1),
    // },
  },
  paper: {
    padding: theme.spacing(4),
    background: 'rgb(255 255 255 / 50%)',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  fileInput: {
    width: '97%',
    margin: '10px 0',
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  radio: {
    '&$order': {
      padding: theme.spacing(1, 0),
    }
  },
  noMar: {
    '&$order': {
      margin:0,
      background: '#fff',
    }
  },
  order: {},
  labelRoot: {
    fontSize: '1rem',
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
}));