import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {Grid} from '@material-ui/core';
import Button from '../Common/Button';
import useStyles from "./styles";
import Input from "../Common/Input";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { email } from "../../actions/email";
import AlertDialog from "../Common/AlertDialog";

const initContactInState = {
  formType: 'contact',
  lookingFor: "",
  name: "",
  address: "",
  region: "",
  country: "",
  phoneNumber: "",
  details: "",
}

const Form = ({ currentId, setCurrentId }) => {
  const [contactForm, setContactForm] = useState(initContactInState);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const userForm = (n, v) => {
    if(initContactInState.hasOwnProperty(n)) setContactForm({ ...contactForm, [n]: v });
  }

  const handleChange = e => userForm(e.target.name, e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit', contactForm);
    setOpen(true);
    dispatch(email(contactForm));
  };

  return (
    <Paper className={classes.paper}>
      <AlertDialog 
        fire={open} 
        title="Thank you!" 
        desc="Your submission has been received." 
        act1=""
        act2="Go to Home"
      />
      <form
        autoComplete="off"
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
        elevation={2}
      >
        <Grid container spacing={2} justifyContent='center'>
        <Input
          name="lookingFor"
          variant="outlined"
          label="I'm looking to..."
          fullWidth
          required
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, lookingFor: e.target.value })
          }
        />
        <Input
          name="name"
          variant="outlined"
          label="My name is..."
          fullWidth
          required
          handleChange={handleChange}
          onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })}
        />
        <Input
          name="address"
          variant="outlined"
          label="Complete Address"
          fullWidth
          required
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, address: e.target.value })
          }
        />
        <Input
          name="region"
          variant="outlined"
          label="State / Province / Region "
          half
          required
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, region: e.target.value })
          }
        />
        <Input
          name="country"
          variant="outlined"
          label="Country"
          half
          required
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, country: e.target.value })
          }
        />
        <Input
          name="phoneNumber"
          variant="outlined"
          label="Phone Number"
          fullWidth
          required
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, phoneNumber: e.target.value })
          }
        />
        <Input
          name="details"
          variant="outlined"
          label="You should know the following..."
          fullWidth
          multiline
          required
          rows={4}
          handleChange={handleChange}
          onChange={(e) =>
            setContactForm({ ...contactForm, details: e.target.value })
          }
        />
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >Get a free quote&nbsp;<ArrowForwardIcon /></Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default Form;