import React, {useState, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import {
  Typography,
  makeStyles, Grow,
} from "@material-ui/core";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '../Common/Button';
import { HashLink } from 'react-router-hash-link';
import CardMedia from '@mui/material/CardMedia';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: theme.spacing(5.5), 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    color: "#202020",
    marginBottom: theme.spacing(2),
    position: "relative",
    marginTop: theme.spacing(2),
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
    paddingLeft: "35px"
  },
  tagHighlight: {
    background: "#202020", 
    height: "2px", 
    width: "25px", 
    display: "block", 
    position: "absolute", 
    left: "0", 
    top: "11px"
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#07AFBB", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "0", 
    left: "0", 
    zIndex: "-1", 
    borderRadius: "5px"
  },
  arrow: {
    '&$order': {
      height: ".8em", 
      width: ".8em", 
    }
  },
  order: {},
  inner: {},
  mb: {
    marginBottom: theme.spacing(2),
  },
  backTile: {
    position: "absolute", 
    top: "-40px", 
    left: 10, 
    right: 0, 
    bottom: "120px", 
    width: "88%", 
    background: "#05BBC7", 
    transform: "rotate(\n15deg)",
    borderRadius: theme.spacing(1.5),
    '&$inner': {
    bottom: '35px',
    left: '-40px',
    top: '-20px',
    width: '100%',
    }
  },
  anc: {
    textDecoration: 'none',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  media: {    
    height: '300px'
  }
}));

function ProductCTA({post}) {
  const [hgt, setHgt] = useState(0)
  const classes = useStyles();
  const el = useRef();
  const onImgLoad = () => setHgt(el.current.clientHeight);

  return (
    <Grow in>
      <Container disableGutters component="section" sx={{ mt: 10, display: 'flex', pb: 11 }}>
        <Grid container>
          <Grid item xs={12} md={post.inner ? 7 : post.portfolio ? 5 : 8} sx={{ zIndex: 1 }}>
            <Box sx={{display: 'flex', justifyContent: 'center', p: 2, pt: 0, pb: 0, width: '90%',}}>
              <Box component="div">
                <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
                    {post.tagline}<span className={classes.tagHighlight}></span></Typography>
                <Typography variant="h2" component="h1" className={classes.headline} gutterBottom>
                  {post.preTitle}&nbsp;{post.title}&nbsp;
                  <div className={classes.highlighter}>{post.highlight}<span className={classes.highlight} /></div>
                  </Typography>
                <Typography component="p" variant="body1" className={classes.mb}>{post.description}</Typography>
                {post.actions.map((action) => (
                  <div>
                    {action.hash ? 
                      <Button color="primary" variant={post.inner ? "gc1" : "contained"} sx={{ mt: 2 }}>
                      <HashLink className={classes.anc} to={action.url}>{action.btn}&nbsp;<ArrowForwardIcon className={`${classes.arrow} ${classes.order}`} /></HashLink></Button> :
                      action.http ? 
                      <Button color="primary" variant={post.inner ? "gc1" : "contained"} sx={{ mt: 2 }}>
                      <a className={classes.anc} target="_blank" href={action.url}>{action.btn}&nbsp;<ArrowForwardIcon className={`${classes.arrow} ${classes.order}`} /></a></Button> :
                      action.form ? 
                        <form method="post" action={action.url}>
                          <input type="hidden" name="askamtflg" value="1" />
                          <input type="hidden" name="card-allowed" value="Visa,Mastercard" />
                          <input type="hidden" name="comments" value=" " />
                          <input type="hidden" name="currency" value="kyd" />
                          <input type="hidden" name="easycart" value="0" />
                          <input type="hidden" name="publisher-email" value="info@caymedia.ky" />
                          <input type="hidden" name="publisher-name" value="caymedia" />
                          <Button type="submit" color="primary" variant={post.inner ? "gc1" : "contained"} sx={{ mt: 2 }}>
                          {action.btn}&nbsp;<ArrowForwardIcon className={`${classes.arrow} ${classes.order}`} /></Button>
                        </form> :
                      <Button to={action.url} component={Link} color="primary" variant={post.inner ? "gc1" : "contained"} sx={{ mt: 2 }}>
                      {action.btn}&nbsp;<ArrowForwardIcon className={`${classes.arrow} ${classes.order}`} /></Button>}
                  </div>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={post.inner ? 5 : post.portfolio ? 7 : 4} 
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
          >
            <Box sx={{position: 'relative', height:`${hgt}px`}} >
            {!post.portfolio && <Box className={post.inner ? `${classes.backTile} ${classes.inner}` : classes.backTile} />}
            {post.image && 
            <Box
              ref={el}
              onLoad={onImgLoad}
              component="img"
              src={post.image}
              alt="call to action"
              sx={{
                position: 'absolute',
                top: -28,
                left: -38,
                right: 0,
                bottom: 0,
                width: '100%',
                borderRadius: 2.5,
              }}
            />}
            {post.video &&
            <CardMedia
              ref={el}
              onLoad={onImgLoad}
              component='iframe'
              title='call to action'
              src={post.video}
              frameborder="0" 
              allowfullscreen='true'
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              sx={{
                position: 'absolute',
                top: -28,
                left: -38,
                right: 0,
                bottom: 0,
                width: '100%',
                borderRadius: 2.5,
              }}
              classes={{
                media: classes.media
              }}
            />}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
}

export default ProductCTA;