import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@mui/material/styles';

import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar/Navbar';
import Drawer from './components/Navbar/Drawer';
import Auth from './components/Auth/Auth';
import Footer from './components/Home/Footer';
import Service from './components/Service';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Pay from './components/Pay';
import Questionaire from './components/Questionaire';

const footer = {
  title: 'Let’s talk about your next',
  highlight: 'business challenge',
  description:
    "We've Worked With Them Now Trust We’ll help to achieve your goals and to grow your business.",
  descriptionTwo:
    "Already have a design in your mind? Contact us to get started today!",
  buttonText: 'get started today',
  btn: true,
  contacts:[
    {
      title: 'Contact Us',
      items: [
        {
          icon: '',
          val: '1-345-925-8824',
          link: 'tel:1-345-925-8824',
        },
        {
          icon: '',
          val: 'info@caymedia.ky',
          link: 'mailto:info@caymedia.ky'
        },
        {
          icon: '',
          val: 'WhatsApp',
          link: 'https://wa.me/13459258824'
        },
        {
          icon: '',
          val: 'Skype',
          link: 'skype:live:rashadrankin2?chat'
        },
      ]
    },
    {
      title: 'Follow Us',
      items: [
        {
        icon: '',
        val: 'Facebook',
        link: 'https://www.facebook.com/CayMediaKY/'
        },
        {
          icon: '',
          val: 'Insta',
          link: 'https://www.instagram.com/caymedia/?hl=en',
        },
        {
          icon: '',
          val: 'LinkedIn',
          link: 'https://www.linkedin.com/company/caymedia/?originalSubdomain=ky',
        },
      ]
    },
  ]
};

const theme = createTheme({
  typography: {
    fontFamily: `"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '0.875rem',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '30px',
      letterSpacing: '0.44px',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '.4px',
    },
    h5: {
      fontSize: '1.75rem',
      fontWeight: 900,
      letterSpacing: '.2px',
      lineHeight: '30px'
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 700,
      letterSpacing: '.1px',
      lineHeight: '25px'
    }
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#089AA5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#59c2b1',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          "fontFamily": `"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif`,
          borderColor: 'rgb(0 0 0 / 23%)',
        },
        "& $inputLabel-outlined": {
          color: 'red'
        }
      } 
    },
  }
});

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      {/* {console.log(theme)} */}
      <Drawer />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/Marketing_Strategy" exact component={()=><Service id={0}/>} />
        <Route path="/services" exact component={()=><Service id={0}/>} />
        <Route path="/Brand_Design_Development" exact component={()=><Service id={1}/>} />
        <Route path="/Website_Design_Development" exact component={()=><Service id={2}/>} />
        <Route path="/Digital_Marketing" exact component={()=><Service id={3}/>} />
        <Route path="/Social_Media_Management" exact component={()=><Service id={4}/>} />
        <Route path="/Ecommerce" exact component={()=><Service id={5}/>} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/pay-now" exact component={Pay} />
        <Route path="/website-questionaire" exact component={Questionaire} />
      </Switch>
      <Footer post={footer}/>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;