import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {
  Typography,
  makeStyles, Grow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headline: {
    fontStyle: "normal", 
    fontWeight: "900", 
    fontSize: "46px", 
    lineHeight: "121.3%", 
    letterSpacing: "-0.025em", 
    color: "#202020",
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  tagline: {
    fontSize: "17px", 
    fontStyle: "normal", 
    fontWeight: "600", 
    lineHeight: "24px", 
    letterSpacing: "0.188em", 
    textAlign: "left",
    textTransform: "uppercase",
    position: "relative", 
    paddingLeft: "0"
  },
  tagHighlight: {
    background: "#202020", 
    height: "2px", 
    width: "25px", 
    display: "block", 
    position: "absolute", 
    left: "0", 
    top: "11px"
  },
  highlighter: {
    position: "relative",
    display: 'inline-block',
  },
  highlight: {
    backgroundColor: "#07AFBB", 
    position: "absolute", 
    height: "8px", 
    width: "100%", 
    bottom: "2px", 
    left: "0", 
    zIndex: "-1", 
    borderRadius: "5px"
  },
  arrow: {
    '&$order': {
      height: ".8em", 
      width: ".8em", 
    }
  },
  order: {},
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clientMar: {
    margin: theme.spacing(4,0),
  }
}));

function ProductCTA({post}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grow in>
      <Container component="section" sx={{ mt: 10, display: 'flex' }}>
        <Grid container>
          <Grid item xs={12} md={7} sx={{ zIndex: 1 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                pb: 10,
                width: '90%',
              }}
            >
              <Box component="div">
                <Typography variant="h2" component="h2" className={classes.tagline} gutterBottom>
                    {post.tagline}</Typography>
                <Typography variant="h2" component="h2" className={classes.headline} gutterBottom>
                  {post.title}&nbsp;
                  <div className={classes.highlighter}>{post.highlight}<span className={classes.highlight} /></div>
                  </Typography>
                  <Grid item xs={8}>
                    <Typography component="p" variant="body1" gutterBottom>{post.description}</Typography>
                  </Grid>
                <Grid container maxWidth='sm' className={classes.clientMar}>
                  {post.brands.map((item, i) => (
                    <Grid item idx={i} xs={12} md={4} className={classes.flexCenter} justifyContent='flex-start'>
                      <img src={item.imgUrl} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: -67,
                left: -67,
                right: 0,
                bottom: 0,
                width: '100%',
              }}
            />
            <Box
              component="img"
              src={post.image}
              alt="call to action"
              sx={{
                position: 'absolute',
                top: -28,
                left: -28,
                right: 0,
                bottom: 0,
                width: '100%',
                maxWidth: 600,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
}

export default ProductCTA;