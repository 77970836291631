import React, {useEffect} from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetQuestions from './GetQuestions';

const useStyles = makeStyles((theme) => ({
  mainBg :{
      margin: 0,
      background: `#fff url(${process.env.REACT_APP_IMG}/v1640604608/CayMedia/bg-2.jpg) repeat-x 0 0`,
      transition: 'background 0.3s ease',
      [theme.breakpoints.down('xl')]: {
        backgroundPosition: '0px -13em',
      },
  },
  section: {
    position: 'relative',
    background: '#fff',
  }
}));

const Questionaire = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add(`${classes.mainBg}`);
  })

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <GetQuestions post={GetQuestions} />
      </Grid>
    </Container>
  );
};

export default Questionaire;